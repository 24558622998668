import IconInputPassword from "components/Password/IconInputPassword";
import styles from "styles/Login.module.css";
import { useUtil } from "hooks/Utils/useUtil"


const FormNewPassword = ({ register, errors, isLoading, getValues, validatePassword }) => {

	const { switchShown: switchShownPass, shown:shownPass } = useUtil()
	const {  switchShown: switchShownRep, shown: shownRep } = useUtil()

    return (
        <>
            <div className={`form-group w-100`}>
					<h3 className={styles["title-account"]}>Resetear <br/>la contraseña</h3>
				</div>
				<div className="form-group mb-4 w-100">
					<div className="p-relative">
						<input 
							type={shownPass ? 'text' : 'password'} 
							className="form-control form__input"
							id="password" 
							name="password"
							aria-describedby="password"
							placeholder="Contraseña"
							ref={register({
								required: { value: true, message: "Requerido" },
								minLength: { value: 9, message: "Mínimo 9 caracteres" },
								maxLength: {
								value: 15,
								message: "Máximo 15 caracteres",
								},
								validate: validatePassword,
								//eslint-disable-next-line
								pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
								message:
									"La contraseña debe tener: al menos 9 caracteres, 1 número, 1 caracter en minúscula, 1 caracter en mayúscula y 1 caracter especial",
								},
							})}
							maxLength={15}
						/>
						<IconInputPassword 
							shown={shownPass}
							switchShown={switchShownPass}
						/>
					</div>
					{(errors?.password?.message && errors?.password?.message!=="")  && (
						<p className="text-danger mb-2 w-100 text-left">
							{errors?.password?.message}
						</p>
					)}
				</div>
				
				<div className="form-group mb-4 w-100">
					<div className="p-relative">
						<input 
							type={shownRep ? 'text' : 'password'} 
							className="form-control form__input"
							id="repPassword" 
							name="repPassword"
							aria-describedby="repPassword"
							placeholder="Confirmar contraseña"
							ref={register({
								required: { value: true, message: "Requerido" },
								minLength: { value: 8, message: "Mínimo 8 caracteres" },
								maxLength: {
								value: 15,
								message: "Máximo 15 caracteres",
								},
								validate: (value) => {
								if (value === getValues()["password"]) {
									return true;
								} else {
									return "Las claves no coinciden";
								}
								},
							})}
							maxLength={15}
						/>
						<IconInputPassword 
							shown={shownRep}
							switchShown={switchShownRep}
						/>
					</div>
					{(errors?.repPassword?.message && errors?.repPassword?.message!=="")  && (
						<p className="text-danger mb-2 w-100 text-left">
							{errors?.repPassword?.message}
						</p>
					)}
				</div>
				 <div className="form-group w-100">
				    <button 
				    	type="submit" 
				    	className="btn btn-primary btn-block mb-4 pt-2 btn-claim"
				    	disabled={isLoading}
				    >{isLoading ? "Procesando..." : "Siguiente"}</button>
				 </div>
        </>
    )
}

export default FormNewPassword