export const downloadDocumento = (data, target="_blank") => {

	let url = data.url?.replace("http", "https");
	let a = document.createElement('a');
	a.href = url;
	a.target = "_blank";
	a.download = `documento_${data.nro_invoice}.pdf`;
	document.body.appendChild(a);
    a.click();
   // document.body.removeChild(a);
//	window.close();
}

/**const getBrowserInfo = () => {
	var ua= navigator.userAgent, tem, 
	M= ua.match(/(opera|chrome|safari|firefox|msie|edg|trident(?=\/))\/?\s*(\d+)/i) || [];
   if(/trident/i.test(M[1])){
		tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
	   return 'IE '+(tem[1] || '');
   }
   if(M[1]=== 'Chrome'){
	   tem= ua.match(/\b(OPR|Edg)\/(\d+)/);
	   if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
   }
   M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
   if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
   return M.join(' ');
};**/