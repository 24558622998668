import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Dashboard.module.css";
import icon_inbox from "assets/images/icon-inbox.svg";


const DashboardWithdrawal = ({ listWithdrawals, isLoadingDashboard, handleOpenDialog }) => {
	

	return (
		<section className="w-100">
            <section className={styles["space-container"]}>
                <p className={styles["space-title"]}>Últimos retiros</p>
            </section>
            <section className={styles["space-item"]}>
                {
                    listWithdrawals?.map((withdrawal, index) => (
                        <div className={`${styles["space-item__container"]} `} key={index}>
                            <div className={styles["space-info"]}>
                                <p className={styles["space-item__title"]}><b>No. retiro:</b> {withdrawal?.number_withdrawal_order}</p>
                                <p className={styles["space-item__title"]}><b>Cant. órdenes:</b> {withdrawal?.count_orders}</p>
                            </div>
                            <div className={styles["space-info"]}>
                                {withdrawal?.from && withdrawal?.state==="Pendiente" ?
                                        <button 
                                            className={`btn btn-light ${styles["btn--orange"]}`}
                                            onClick={() => handleOpenDialog(withdrawal)}
                                        >
                                            Descargar ticket
                                        </button>
                                     : 
                                     <div className={`badge ${styles["space-badge"]} ${styles["badge-state"]} ${styles[withdrawal?.state==="Pagada" ? "space-badge--green" : "space-badge--pink"]}`}>
                                                {withdrawal?.state}
                                    </div>
                                }
                                <p className={`text-right ${styles["space-item__title"]}`}><b>{withdrawal?.date_order}</b></p>
                            </div>
                        </div>
                    ))
                }
                {
                    isLoadingDashboard && Array.from([1,2,3,4,5])?.map(x => 
                        <div className={`${styles["space-item__container"]} `} key={x}>
                            <div className={styles["space-info"]}>
                                <p className={styles["space-item__title"]}><b>No. retiro:</b> <Skeleton width={70} count={1} /></p>
                                <p className={styles["space-item__title"]}><b>Cant. órdenes:</b> <Skeleton width={70} count={1} /></p>
                            </div>
                            <div className={styles["space-info"]}>
                            <Skeleton width={70} count={1} />
                                <p className={`text-right ${styles["space-item__title"]}`}><b><Skeleton width={70} count={1} /></b></p>
                            </div>
                        </div>
                    )
                }

                {
                    !isLoadingDashboard && listWithdrawals?.length===0 && (
                        <div className={styles["options-not-records"]}>
                            <div><img src={icon_inbox} alt="No hay registros" /></div> 
                            <p className={styles["item-text__subtitle-fact"]}>
                                No hay retiros disponibles
                            </p>
                        </div>
                    )
                }
            </section>
        </section>
	);

};

export default DashboardWithdrawal;
