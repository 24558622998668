import { useEffect } from "react"
import Layout from "components/Layout/Layout";
import styles from "styles/Orden.module.css";
import Skeleton from 'react-loading-skeleton'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import 'react-loading-skeleton/dist/skeleton.css'

import { FaCheck } from "react-icons/fa";
import { useSearchOrder } from "hooks/TransportOrders/useSearchOrder"
import { useParams } from "react-router-dom";

const BuscarOrden = () => {

	const { order_number } = useParams()

	const { state, actions } = useSearchOrder()
	const { getDataInitial } = actions
	const { isLoadingSearch, dataTransportOrder } = state

	useEffect(() => {
		getDataInitial(order_number)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order_number])
	

	return (
		<Layout
			modulo="inicio"
		>
			<div className="row padding-menu-top">
				<div className="col-xs-12 col-lg-4">
					{
						!isLoadingSearch && (
							<VerticalTimeline layout="1-column" lineColor="#342580">
								{
									dataTransportOrder?.status_timeline?.map((item, index) => (
										<VerticalTimelineElement
											key={index}
											className="vertical-timeline-element--work"
											contentStyle={{ background: '#e3e3e3', color: 'black' }}
											contentArrowStyle={{ borderRight: '7px solid  #e3e3e3' }}
											date={item?.date}
											iconStyle={{ 
												background: item?.status ? '#00A832' : 'white', 
												color: 'white', 
												boxShadow: item?.status ? 
														'0 0 0 4px #00A832, inset 0 0px 0 rgb(0 0 0 / 8%), 0 0px 0 0px rgb(2 2 2 / 5%)' :
														'rgb(143 143 143) 0px 0px 0px 4px, rgba(0, 0, 0, 0.08) 0px 0px 0px inset, rgba(2, 2, 2, 0.05) 0px 0px 0px 0px'
												}}
											icon={item?.status ? <FaCheck /> : null}

										>
											<h3 className="vertical-timeline-element-title">{item?.description}</h3>
										</VerticalTimelineElement>
									))
								}
								<div></div>
							</VerticalTimeline>
						)
					}
					{
						isLoadingSearch && (
							<Skeleton width={"100%"} height={"100vh"} count={1} />
						)
					}
				
				</div>
				<div className="col-xs-12 col-lg-8">
					<section>
						<p className={styles["title"]}>Detalle de la orden</p>
						<div className={`${styles["item__container"]} pt-2`}>
							<div className={styles["item"]}>
								<p className={styles["item__title"]}>
									<b>Nro. orden:</b> {order_number}
								</p>
								<p className={styles["item__title"]}>
									<b>Forma de pago:</b> 
									<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.forma_pago}</span>
								</p>
								<p className={styles["item__title"]}>
									<b>Origen:</b> 
									
									{ isLoadingSearch ? 
										<span className="ml-2">  <Skeleton width={100} count={1} /></span> : 
										<><span className="ml-2">{dataTransportOrder?.sucursal_origen}</span><span className={styles["item-hidden"]}>-{dataTransportOrder?.oficina_origen}</span></>
									}
									
								</p>
							</div>
							<div className={styles["item"]}>
							<p className={styles["item__title"]}>
								<b>Fecha:</b> 
								<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.fecha_orden_format}</span>
							</p>
								<p className={styles["item__title"]}>
									<b>Tipo de envío:</b> 
									<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.tipo_envio}</span>
								</p>
								<p className={styles["item__title"]}>
									<b>Destino:</b> 
									{ isLoadingSearch ? 
										<span className="ml-2">  <Skeleton width={100} count={1} /></span> : 
										<><span className="ml-2">{dataTransportOrder?.sucursal_destino}</span> <span className={styles["item-hidden"]}>-{dataTransportOrder?.oficina_destino}</span></>
									}
								</p>
							</div>
						</div>					
						<p className={styles["item__title"]}>
							<b>Notificar a:</b> 
							<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.email_notification}</span>
						</p>
						<p className={styles["item__title"]}>
							<b>Observación:</b> 
							<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.comments}</span>
						</p>
						<div className={styles["item-line"]} />

						<p className={`${styles["item-subtitle"]} pt-4`}>Remitente</p>
						<div className={`${styles["item__container"]} pt-0`}>
							<div className={styles["item"]}>
								<p className={styles["item__title"]}>
									<b>RUT:</b> 
									<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.rut_remitente}</span>
								</p>
							</div>
							<div className={styles["item"]}>
								<p className={styles["item__title"]}>
									<b>Telefóno:</b> 
									<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.telefono_remitente}</span>
								</p>
							</div>
						</div>	
						<p className={styles["item__title"]}>
							<b>Razón Social:</b> 
							<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.razon_social_remitente}</span>
						</p>	
						<p className={styles["item__title"]}>
							<b>Dirección:</b> 
							<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.direccion_remitente}</span>
						</p>	
						<div className={styles["item-line"]} />

						<p className={`${styles["item-subtitle"]} pt-4`}>Destinatario</p>
						<div className={`${styles["item__container"]} pt-0`}>
							<div className={styles["item"]}>
								<p className={styles["item__title"]}>
									<b>RUT:</b> 
									<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.rut_destinatario}</span>
								</p>
							</div>
							<div className={styles["item"]}>
								<p className={styles["item__title"]}>
									<b>Telefóno:</b> 
									<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.telefono_avisas}</span>
								</p>
							</div>
						</div>	
						<p className={styles["item__title"]}>
							<b>Razón Social:</b> 
							<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.razon_social_destinatario}</span>
						</p>	
						<p className={styles["item__title"]}>
							<b>Dirección:</b> 
							<span className="ml-2">  { isLoadingSearch ? <Skeleton width={100} count={1} /> : dataTransportOrder?.direccion_destinatario}</span>
						</p>	
						<div className={styles["item-line"]} />

						<p className={`${styles["item-subtitle"]} pt-4`}>Detalle de la carga</p>
						<div className="table-responsive">
								<table className={`table table-hover ${styles["table"]}`}>
									<thead>
										<tr>
											<th scope="col">Bultos</th>
											<th scope="col">Kilos</th>
											<th scope="col">Largo</th>
											<th scope="col">Ancho</th>
											<th scope="col">Alto</th>
											<th scope="col">M3</th>
											<th scope="col">Detalle de la carga</th>
										</tr>
									</thead>
									<tbody>
										{
											!isLoadingSearch && dataTransportOrder?.cargas?.map((item, index) => (
												<tr key={index}>
													<td>{item?.bulto}</td>
													<td>{item?.peso}</td>
													<td>{item?.largo}</td>
													<td>{item?.ancho}</td>
													<td>{item?.alto}</td>
													<td>{item?.metraje_cubico}</td>
													<td>{item?.contenido}</td>
												</tr>
											))
										}
										{
											isLoadingSearch && (
												<tr>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
												</tr>
											)
										}
									</tbody>
								</table>
						</div>

						<p className={`${styles["item-subtitle"]} pt-4`}>Detalle de los estados</p>
						<div className="table-responsive">
								<table className={`table table-hover ${styles["table"]}`}>
									<thead>
										<tr>
											<th scope="col">Fecha</th>
											<th scope="col">Hora</th>
											<th scope="col">Descripción</th>
											<th scope="col">Observación</th>
										</tr>
									</thead>
									<tbody>
										{
											!isLoadingSearch && dataTransportOrder?.listEstado?.map((item, index) => (
												<tr key={index}>
													<td>{item?.fecha}</td>
													<td>{item?.hora}</td>
													<td>{item?.descripcion}</td>
													<td>{item?.observaciones}</td>
												</tr>
											))
										}
										{
											isLoadingSearch && (
												<tr>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
													<td><Skeleton width={100} count={1} /></td>
												</tr>
											)
										}
									</tbody>
								</table>
						</div>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default BuscarOrden;