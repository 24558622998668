import { Modal } from 'react-bootstrap';
import close_btn from "assets/images/close.png";
import styles from "styles/Retiro.module.css";
import 'react-loading-skeleton/dist/skeleton.css'


const DialogInternalNumberCustomer = ({ show, handleClose, data, handleSelectItem, textDialog }) => {

	return (
		<Modal 
			show={show} 
			onHide={handleClose} 
			className={styles["modal-withdrawals"]} 
			centered
			size="md"
			>
	        <Modal.Header className={styles["header-modal"]}>
	          <Modal.Title className={styles["title-modal"]}>Iniciar sesión</Modal.Title>
	          <div aria-hidden="true" className={styles["btn-close"]} onClick={()=>handleClose()}>
	          	<img src={close_btn} alt="Cerrar" width={12} heigth={12} className="modal-close" />
	          </div>
	        </Modal.Header>
	        <Modal.Body className="p-5" >
				<div className="col-12 mb-3">
					<p className={`${styles["text-template__title"]}`}>
						Bienvenido
					</p>
					<p className={`${styles["text-template__subtitle"]}`}>
					{textDialog}
					</p>
				</div>
				<div className={`col-8 pt-5 ${styles["template-container"]}`}>
					{
						data?.length>0 &&
						data?.map((item) =>
							<button 
									key={item?.id}
									type="button"
									className={`btn btn-outline-primary btn-block mb-4 btn-claim`}
									onClick={()=>handleSelectItem(item)}
								>
								{item?.name}
							</button>
						)
					}
				</div>
				
			
	        
	        </Modal.Body>
	    </Modal>
	)
}

export default DialogInternalNumberCustomer