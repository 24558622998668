import { useEffect } from "react"
import Layout from "components/Layout/Layout";
import DashboardOrder from "components/TransportOrders/DashboardOrder"
import DashboardWithdrawal from "components/TransportOrders/DashboardWithdrawal";
import DashboardCount from "components/TransportOrders/DashboardCount"
import DashboardInvoice from "components/TransportOrders/DashboardInvoice";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDashboardOrder } from "hooks/TransportOrders/useDashboardOrder"
import FormPreview from "components/Withdrawals/Detail/FormPreview";

const Dashboard = () => {

	const { actions, state } = useDashboardOrder()
	
	const { listTransportOrders, isLoadingDashboard, listWithdrawals, countedOrders, listInvoices, showDialog, infoPackage } = state
	const { getDataInitial, handleCloseDialog, handleOpenDialog } = actions

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<Layout
			modulo="inicio"
		>
			<div className="row  padding-menu-top">
				<div className="col-12 col-lg-7 col-xl-8">
					<DashboardOrder 
						listTransportOrders={listTransportOrders}
						isLoadingDashboard={isLoadingDashboard}
					/>
					
				</div>
				<div className="col-12 col-lg-5 col-xl-4 pt-4 pt-lg-0">
					<DashboardWithdrawal 
						listWithdrawals={listWithdrawals}
						isLoadingDashboard={isLoadingDashboard}
						handleOpenDialog={handleOpenDialog}
					/>
				</div>
			</div>
			<div className="row pt-5 mt-5">
				<div className="col-12 col-lg-7 col-xl-8">
					<DashboardInvoice 
						listInvoices={listInvoices}
						isLoadingDashboard={isLoadingDashboard}
					/>
					
				</div>
				<div className="col-12 col-lg-5 col-xl-4 pt-4 pt-lg-0">
					<DashboardCount 
						countedOrders={countedOrders}
						isLoadingDashboard={isLoadingDashboard}
					/>
				</div>
			</div>

			{
				showDialog && 
				<FormPreview 
					printNroRetiro={infoPackage} 
					show={showDialog}
					handleClose={handleCloseDialog}
				/>
			}
		</Layout>
	);

};

export default Dashboard;
