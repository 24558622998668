import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import styles from "styles/Login.module.css";

const ResetPassword = ({ formElement, state, handleBlurRut, handleChangeEmail }) => {

    const {  register, errors, setError, control } = formElement
    const { isLoading, visibleEmail, dataForget } = state

    return (
        <>
            
            <div className={`form-group  w-100`}>
                <h3 className={styles["title-account"]}>¿Se te olvidó <br/>la contraseña?</h3>
                {
                    !visibleEmail ? (
                        <p className={styles["text-account"]}>No te preocupes, podemos ayudarte, <br />ingresa tu RUT para continuar:</p>
                    ) : (
                        <p className={styles["text-account"]}>Verifica el email y la empresa de transporte.<br /><br /> Si encuentras algún error, contacta inmediatamente a la empresa de envío.</p>
                    )
                }
            </div>

            <div className={`form-group mb-4 w-100`}>
                
                <Controller
                    render={(props) => (
                        <MaskedInput
                            mask={RutTextMask}
                            className="form-control form__input"
                            placeholder="RUT"
                            id="rut" 
                            name="rut"
                            value={props.value}
                            onChange={(value)=>{
                                props.onChange(value)
                            }}
                            onBlur={(event) => handleBlurRut(event, setError)}
                            readOnly={visibleEmail}
                        />
                    )}
                    control={control}
                    name="rut"  
                    rules={{required: {value: true, message: 'Requerido'}}}
                />
                {errors?.rut && errors?.rut?.message!=="" && (
                    <p className="text-danger w-100 text-left">
                        {errors?.rut?.message}
                    </p>
                )}
            </div>
            

            {
                visibleEmail && (
                    <>
                    {
                        dataForget?.companies?.length>1 ? (
                                <div className={`form-group mb-4 w-100`}>
                                    <Controller
                                            control={control}
                                            name="company_id"
                                            rules={{ required: {value: true, message: 'Requerido'} }}
                                            render = {(props) => {
                                                return (
                                                <select 
                                                    value={props.value}
                                                    className="form-control form-group-select"
                                                    id="company_id"   
                                                    onChange={e => {                              
                                                        props.onChange(e.target.value);
                                                    }}                         
                                                >
                                                    <option value="">Selecciona la empresa de transporte</option>
                                                    { 
                                                        dataForget?.companies.length>0 && dataForget?.companies.map((item, key) => 
                                                            <option value={item.id} key={key}>{item.name}</option>
                                                        )
                                                    }
                                                </select>
                                            )}}
                                    />	
                                     {errors.company_id && (
                                        <p className="text-danger w-100 text-left">
                                            {errors?.company_id?.message}
                                        </p>
                                    )}
                                </div>
                        ) : (
                            <div className={`form-group w-100`}>
                                <input 
                                    type="company_name" 
                                    className="form-control form__input"
                                    id="company_name" 
                                    name="company_name"
                                    aria-describedby="company_name"
                                    maxLength={255}
                                    placeholder="Empresa de transporte"
                                    ref={register}
                                    readOnly={true}
                                />
                            </div>
                        )
                    }
                        
                        <div className={`form-group mb-4 w-100 mt-3`}>
                            <input 
                                type="email" 
                                className="form-control form__input"
                                id="email" 
                                name="email"
                                aria-describedby="email"
                                maxLength={200}
                                placeholder="Correo electrónico"
                                ref={register({
                                    required: {value: true, message: 'Requerido'},
                                    //eslint-disable-next-line
                                    pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
                                })}
                                readOnly={true}
                            />
                            {errors.email && errors?.email?.message!=="" && (
                                <p className="text-danger w-100 text-left">
                                    {errors?.email?.message}
                                </p>
                            )}
                        </div>
                    </>
                )
            }
            
            <div className="form-group w-100 pt-4">
                <button 
                    type="submit" 
                    className="btn btn-primary btn-block mb-4 pt-2 btn-claim"
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Siguiente"}</button>
            </div>
        </>
    )
}

export default ResetPassword