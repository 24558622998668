import { useState } from "react"
import { saveFormDestionation, nextFormPreOrden } from "services/actions/withdrawalAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from 'uuid'
import { useEffect } from "react"
import { useReceive } from "hooks/WithDrawals/useReceive"
import { useCustomer } from "hooks/Customers/useCustomer"

export const useDestination = (toast) => {

    const dispatch = useDispatch()
    const { infoDestination, formPreOrden } = useSelector((state) => state.preorden)
    const { companyDataBasic } = useSelector((state) => state.users)
    const { cargarOficinas, oficinaDestino } = useReceive(toast)
    const [ isReadOnlyCustomer, setIsReadOnlyCustomer ] = useState(true)

    const { validatePhone, handleBlurRUT } = useCustomer()

    const { register, handleSubmit, errors, control, setError, setValue, reset } = useForm({})

    const onSubmit =  (data) => {
        try{

                let sucursal_destino = document.querySelector("#sucursal_destino_id");
			    let comuna_destino = document.querySelector("#comuna_destino_id");

                const destionations = [{
                    razon_social: data?.razon_social,
                    email: data?.email,
                    direccion: data?.direccion,
                    internal_code_number: "",
                    rut: data?.rut,
                    telefono: data?.telefono,
                    sucursal_destino_id: parseInt(data?.sucursal_destino_id),
                    sucursal_name: sucursal_destino?.options[sucursal_destino.selectedIndex]?.text,
                    comuna: parseInt(data?.comuna),
                    comuna_name: comuna_destino?.options[comuna_destino.selectedIndex]?.text,
                    listPackages: [],
                    id: uuidv4(),
                    forma_pago_id: data?.forma_pago_id
                }]

                dispatch(saveFormDestionation(destionations, "destination"))

                dispatch(nextFormPreOrden(formPreOrden.multiple_destinatation ?  "downloadTemplate" : "package"))

        }catch(error){
                toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
        }
    }

    const lastFormPreOrden = (nameForm) => {
            dispatch(nextFormPreOrden(nameForm))
    }

    const validateOnlyRUT = async (event) => {
      
        if (event.keyCode === 13) {
          event.preventDefault();
          const result = await handleBlurRUT(event, "session");
       
          resetDataCustomer(result, false)
        }else{
                setIsReadOnlyCustomer(true)
                setValue('razon_social', '')
                setValue('telefono', '')
                setValue('email', '')
                setValue('direccion', '')
        }
  };

  const resetDataCustomer = (data, isReadOnly) => {
        setIsReadOnlyCustomer(isReadOnly)
        reset({
           rut: data?.rut,
           razon_social: data?.full_name,
           telefono: data?.phone_number,
           email: data?.email,
           direccion: data?.address
        })
  }

    useEffect(() => {
        const infoDest = infoDestination.length>0 ? infoDestination[0] : null
        
        const sucursal = infoDest?.sucursal_destino_id ?? ""
        if(sucursal!==""){
                cargarOficinas(undefined, "destino", sucursal)
        }
        reset({
                razon_social: infoDest?.razon_social ?? "",
                rut: infoDest?.rut ?? "",
                telefono: infoDest?.telefono ?? "",
                comuna: infoDest?.comuna ?? "",
                direccion: infoDest?.direccion ?? "",
                sucursal_destino_id: infoDest?.sucursal_destino_id ?? "",
                email: infoDest?.email ?? "",
                forma_pago_id: infoDest?.forma_pago_id ?? "",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const actions = {
        validatePhone,
        validateOnlyRUT
    }

    const formElement = {
        onSubmit,
        register,
        handleSubmit,
        errors,
        control,
        setError,
        setValue,
    }

    const state = {
        isReadOnlyCustomer,
        companyDataBasic
    }


    return {
        formElement,
        formPreOrden,
        lastFormPreOrden,
        cargarOficinas,
        oficinaDestino,
        actions,
        state
}
}