export const FORM_PREORDEN = "FORM_PREORDEN";
export const RESET_FORM_PREORDEN = "RESET_FORM_PREORDEN";
export const NEXT_FORM_PREORDEN = "NEXT_FORM_PREORDEN";
export const SAVE_PACKAGES = "SAVE_PACKAGES";
export const LIST_RETIROS = "LIST_RETIROS";
export const SAVE_FORM_PREORDEN = "SAVE_FORM_PREORDEN";
export const CHANGE_MENU_OPTIONS = "CHANGE_MENU_OPTIONS";
export const CHANGE_MENU_OPTIONS_RETIRO = "CHANGE_MENU_OPTIONS_RETIRO";

export const LIST_INITIAL_DASHBOARD = "LIST_INITIAL_DASHBOARD";
export const LOADING_INITIAL_DASHBOARD = "LOADING_INITIAL_DASHBOARD";
export const LOADING_INITIAL_RETIRO = "LOADING_INITIAL_RETIRO";
export const LIST_INITIAL_RETIRO = "LIST_INITIAL_RETIRO";
export const DATA_INVOICE = "DATA_INVOICE";
export const SELECTED_MULTIPLE_DESTINATATION = "SELECTED_MULTIPLE_DESTINATATION";
export const FORM_PREORDEN_DESTINATION = "FORM_PREORDEN_DESTINATION";
export const FORM_PREORDEN_PACKAGE = "FORM_PREORDEN_PACKAGE";
export const RESET_DESTINATION = "RESET_DESTINATION";


export const DATA_PACKAGE_ALL = "DATA_PACKAGE_ALL";
export const DATA_SEARCH_PACKAGE_ALL = "DATA_SEARCH_PACKAGE_ALL";
export const GET_DATA_PACKAGE = "GET_DATA_PACKAGE";

export const DATA_PACKAGE_ALL_RETIRO = "DATA_PACKAGE_ALL_RETIRO";
export const UPDATE_CONFIG_FORM_RETIRO = "UPDATE_CONFIG_FORM_RETIRO";

export const OPEN_DIALOG = "OPEN_DIALOG"
export const DATA_ORDER_ALL = "DATA_ORDER_ALL"
export const COUNTED_ORDER_ALL = "COUNTED_ORDER_ALL"