import { useEffect } from "react"
import BaseLayoutLogin from "components/Layout/BaseLayoutLogin";
import IconInputPassword from "components/Password/IconInputPassword";
import DialogInternalNumberCustomer from "components/Login/DialogInternalNumberCustomer"
import ModalSpinner from "components/Custom/ModalSpinner"
import delivery_mobile from "assets/images/delivery-mobile.svg";
import logo from "assets/images/logo.svg";
import styles from "styles/Login.module.css";
import { useLogin } from "hooks/Login/useLogin";

import { Controller } from "react-hook-form";
import MaskedInput from 'react-text-mask';
import RutTextMask from 'rut-text-mask';

export default function Login () {

	const { formElement, state, actions } = useLogin()
	const { handleCloseDialogInternal, handleSelectItem } = actions
	const { onSubmit, register, handleSubmit, errors, control } = formElement

	const { isLoading, switchShown, shown, dialogInternalCode, listDataUser, textDialog } = state

	useEffect(() => {
		if(localStorage.getItem("user")){
			localStorage.clear()
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<BaseLayoutLogin>
			<form className={styles["form-login"]} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<input type="email" name="email" value="something@gmail.com" onChange={()=>null} className="label-hidden"/>
				<div className={styles["group-image__logo"]}>
					<img src={logo} alt="logo" width={147} height={96} />
				</div>
				<div className={styles["group-image"]}>
					<img src={delivery_mobile} alt="Login" width={230} height={128} />
				</div>
				<section className={styles["form-section"]}>
					<div className={`form-group ${errors?.rut?.message==="Requerido" ? "mb-1" : "mb-4"}`}>
						<Controller
							render={(props) => (
								<MaskedInput
									type="text"
									mask={RutTextMask}
									className="form-control form__input" 
									placeholder="RUT"
									id="rut" 
									name="rut"
									value={props.value}
									onChange={(value)=>{
										props.onChange(value)
									}}
									//onBlur={(event) => handleSearchClientByCode(event)}
								/>
							)}
							control={control}
							name="rut"  
							rules={{required: {value: true, message: 'Requerido'}}}
						/>
					</div>
					{errors.rut && (
						<p className="text-danger mb-4 w-100 text-left">
							{errors?.rut?.message}
						</p>
					)}
					<div className="form-group p-relative mb-4">
						<input 
							type={shown ? 'text' : 'password'} 
							className="form-control form__input"
							id="password" 
							name="password"
							aria-describedby="password"
							placeholder="Contraseña"
							ref={register({required: {value: true, message: 'Requerido'}})}
						/>
						<IconInputPassword 
								shown={shown}
								switchShown={switchShown}
							/>	
						{errors.password && (
							<p className="text-danger w-100 text-left">
								{errors?.password?.message}
							</p>
						)}
					</div>
											
					<div className={styles["group-check"]}>
						
						<div className="form-group text-right">
							<a href="/account/password" className={styles["text-small__link"]}>¿Olvidó su contraseña?</a>
						</div>
					</div>
					<div className="form-group">
						<button 
							type="submit" 
							className="btn btn-primary btn-block btn-claim"
							disabled={isLoading}
						>{isLoading ? "Procesando..." : "Iniciar sesión"}</button>
					</div>
					{/**<div className="form-group">
						<button 
							type="submit" 
							className="btn btn-outline-primary btn-block"
						><span className={styles["icon-google"]}></span>Iniciar sesión con Google</button>
					</div>**/}								
					{/**<div className="form-group">
						<p className={styles["text-small"]}>
							Aún no tienes una cuenta?
							<a href="/registrarse" className={styles["text-small__link"]}>Registrate</a>
						</p>
					</div> */}
				</section>
			</form>
			{
				dialogInternalCode && (
					<DialogInternalNumberCustomer
						show={dialogInternalCode}
						handleClose={handleCloseDialogInternal}
						data={listDataUser}
						handleSelectItem={handleSelectItem}
						textDialog={textDialog}
					/>
				)
			}
			{isLoading && (
				<ModalSpinner
				spinnerShow={isLoading}
				description="Espere unos segundos, estamos procesando la información..."
				/>
			)}
		</BaseLayoutLogin>
	);
};