import styles from "styles/Retiro.module.css";
import { useReceive } from "hooks/WithDrawals/useReceive"
import { useUtil } from "hooks/Utils/useUtil"
import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'


const FormReceive = ({ handleClose, nextForm, toast, sucursales }) => {

	const { onSubmit, register, handleSubmit, errors, control, 
			onChangeInput, setError, setValue, 
			cargarOficinas, oficinaOrigen, formPreOrden } = useReceive(toast)
	
	const { handleBlurRut, handleChangeEmail, handleChangePhoneNumber } = useUtil()

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className="mt-4">
				<div className="row mb-4">
				<div className="col">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="rut_remitente">RUT</label>
				      	<Controller
	                        render={(props) => (
	                            <MaskedInput
	                                mask={RutTextMask}
	                                className="form-control form__withadral_input" 
	                                id="rut_remitente" 
	                                name="rut_remitente"
	                                value={props.value}
									readOnly
	                                onChange={(value)=>{
	                                    props.onChange(value)
	                                    onChangeInput(value, "text", "rut_remitente")
	                                }}
	                                onBlur={(event) => handleBlurRut(event, setError)}
	                            />
	                        )}
	                        control={control}
	                        name="rut_remitente"  
	                        rules={{required: {value: true, message: 'Requerido'}}}
	                    />
				      	{(errors.rut_remitente && errors.rut_remitente.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.rut_remitente?.message}
					    	</p>
					    )}
				    </div>
				    <div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="razon_social_remitente">Nombre y Apellido</label>
				      	<input 
				      		type="text" 
				      		className="form-control form__withadral_input" 
				      		id="razon_social_remitente" 
				      		name="razon_social_remitente"
				      		ref={register({required: {value: true, message: 'Requerido'}})}
							readOnly
				      	/>
				      	{(errors.razon_social_remitente && errors.razon_social_remitente.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.razon_social_remitente?.message}
					    	</p>
					    )}
				    </div>
				</div>
				<div className="row mb-4">
				    <div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="telefono_remitente">Teléfono</label>
				      	<input 
				      		type="text" 
				      		className="form-control form__withadral_input" 
				      		id="telefono_remitente" 
				      		name="telefono_remitente"
				      		maxLength={9}
				      		pattern="[0-9]+"
                            onChange={(event)=>handleChangePhoneNumber(event, "", setError, setValue, "telefono_remitente")}
                            onBlur={(event) => onChangeInput(event, "text", "telefono_remitente")}
				      		ref={register({
								required: {value: true, message: 'Requerido'},
								minLength: {value: 9, message: 'Mínimo 9 dígitos'}
							})}
							readOnly
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
								}
							}}
				      	/>
				      	{(errors.telefono_remitente && errors.telefono_remitente.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.telefono_remitente?.message}
					    	</p>
					    )}
				    </div>
					<div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="telefono_remitente">E-mail</label>
				      	<input 
				      		type="email" 
				      		className="form-control form__withadral_input" 
				      		id="email_remitente" 
				      		name="email_remitente"
                            onChange={(event)=>handleChangeEmail(event, "", setError, setValue, "email_remitente")}
                            onBlur={(event) => onChangeInput(event, "text", "email_remitente")}
				      		ref={register({
								required: {value: true, message: 'Requerido'},
								//eslint-disable-next-line
								pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
							})}
							readOnly
				      	/>
				      	{(errors.email_remitente && errors.email_remitente.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.email_remitente?.message}
					    	</p>
					    )}
				    </div>
				</div>
				<div className="row mb-4">
				    <div className="col">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="comuna_origen_id">Sucursal</label>
						<Controller
	                        control={control}
	                        name="sucursal_origen_id"
	                        rules={{ required: {value: true, message: 'Requerido'} }}
	                        render = {(props) => {
	                            return (
	                            <select 
	                                value={props.value}
	                                className="form-control form__withadral_input"
	                                id="sucursal_origen_id"   
	                                onChange={e => {                              
	                                    props.onChange(e.target.value);
	                                    cargarOficinas(e, "origen", "")
	                                }}                         
	                            >
	                                <option value="">-Seleccione-</option>
	                                {
	                                	sucursales.length>0 && sucursales.map((item, key) => 
	                                		<option value={item.id} key={key}>{item.name}</option>
	                                	)
	                                }
	                            </select>
	                        )}}
	                        defaultValue={formPreOrden?.sucursal_origen_id}
	                />
						{errors.comuna_origen_id && (
					    	<p className="text-danger mb-4">
					      		{errors?.comuna_origen_id?.message}
					    	</p>
					    )}
				    </div>
					<div className="col">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="comuna_origen_id">Comuna</label>
						<Controller
	                        control={control}
	                        name="comuna_origen_id"
	                        rules={{ required: {value: true, message: 'Requerido'} }}
	                        render = {(props) => {
	                            return (
	                            <select 
	                                value={props.value}
	                                className="form-control form__withadral_input"
	                                id="comuna_origen_id"   
	                                onChange={e => {                              
	                                    props.onChange(e.target.value);
	                                }}                         
	                            >
	                                <option value="">-Seleccione-</option>
	                                {
	                                	oficinaOrigen.length>0 && oficinaOrigen.map((item, key) => 
	                                		<option value={item.id} key={key}>{item.name}</option>
	                                	)
	                                }
	                            </select>
	                        )}}
	                        defaultValue={formPreOrden?.comuna_origen_id}
	                />
						{errors.comuna_origen_id && (
					    	<p className="text-danger mb-4">
					      		{errors?.comuna_origen_id?.message}
					    	</p>
					    )}
				    </div>
				</div>
				
				<div className="row mb-4">
				    <div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="direccion_retiro">Dirección</label>
				      	<textarea 
				      		className="form-control form__withadral_area" 
				      		id="direccion_retiro" 
				      		name="direccion_retiro"
				      		rows={3}
				      		cols={3}
				      		ref={register({required: {value: true, message: 'Requerido'}})}
				      	></textarea>
				      	{errors.direccion_retiro && (
					    	<p className="text-danger mb-4">
					      		{errors?.direccion_retiro?.message}
					    	</p>
					    )}
				    </div>
				</div>
				<div className={styles["btn-modal"]}>
				  	<button 
				  		className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
				  		onClick={handleClose}
				  	>
			            Cancelar
			        </button>
			        <button 
			        	type="submit"
			        	className="btn btn-secondary btn-size-33 btn-block mt-0" 
			        >
			            Siguiente
			        </button>
				</div>
				<div className="row mb-3">
				  	<div className={`col ${styles["item-container"]}`}>
				  		<div className={styles["item-selected"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  	</div>
				</div>
			</section>
		</form>
	);
};

export default FormReceive;