import { useState } from "react"
import { forgotPassword, codeResetPassword, resetNewPassword, searchCustomerPassword } from "services/actions/userAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'

export const usePassword = () => {

	const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ nextForm, setNextForm ] = useState("form-forgot-password")
	const [ visibleEmail, setVisibleEmail ] = useState(false)

	const { register, handleSubmit, errors, setError, setValue, control, getValues } = useForm()
	const { dataForget } = useSelector((state) => state.users)  

	const onSubmit = async (data, event) => {
		try{
  
			setIsLoading(true)

			let response = ""

			
			if(nextForm==="form-forgot-password"){

				let body = {
					rut: data?.rut
				}
	
				response = await dispatch(searchCustomerPassword(body))
			}else if(nextForm==="form-forgot-verified"){

				let company_id = ""
				if(data?.hasOwnProperty('company_id')){
					company_id=data?.company_id
				}else{
					company_id = dataForget?.companies[0]?.id
				}

				let body = {
					rut: data?.rut,
					company_id
				}
	
				response = await dispatch(forgotPassword(body))
			}else if(nextForm==="form-code-password"){

				response = await dispatch(codeResetPassword(data))
				
			}else if(nextForm==="form-new-password"){

				if(data.password.length<6){
					setIsLoading(false)
					toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
					return false
				}
	
				if(data.repPassword.length<6){
					setIsLoading(false)
					toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
					return false
				}
	
				if(data.password!==data.repPassword){
					setIsLoading(false)
					toast.error("Las contraseñas deben ser iguales", {position: toast.POSITION.TOP_RIGHT})
					return false
				}

				let body = { password: data?.password}
				response = await dispatch(resetNewPassword(body))
			}

			setIsLoading(false)

			if(response.status){
		
				if(nextForm==="form-forgot-password"){
				
					setVisibleEmail(visibleEmail => response?.data?.email!=="" ?  true : false)
					setValue('email', response?.data?.email)
					if(response?.data?.companies.length===1){
						setValue('company_name', response?.data?.companies[0]?.name)
					}
					setNextForm(nextForm => "form-forgot-verified")
				}else if(nextForm==="form-forgot-verified"){
				
					setNextForm(nextForm => "form-code-password")
				}else if(nextForm==="form-code-password"){
					setNextForm(nextForm => "form-new-password")
				}else if(nextForm==="form-new-password"){
					setNextForm(nextForm => "form-message")
				}
			
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}

		}catch(error){

			setIsLoading(false)
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}


	const validatePassword = (value) => {
        if (value !== getValues("password")) {
          return "Las contraseña deben ser iguales";
        }
      };


	const state = {
		isLoading,
		nextForm,
		visibleEmail, 
		dataForget
	}


	const formElement = {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		setError,
		setValue,
		getValues,
		validatePassword
	}

	return {
		state,
		formElement	
	}
}