import styles from "styles/Login.module.css";
import { ToastContainer } from 'react-toastify'
import delivery_dsk from "assets/images/delivery-dsk.svg";
import group_login from "assets/images/group-login.svg";

const BaseLayoutLogin = ({ children }) => {

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className={`col-md-7 ${styles["bg-dsk"]}`}>
						<div className={styles["dsk-container"]}>
							<div>
								<img src={delivery_dsk} alt="Login" className={styles["dsk-container__img"]} height={255} />
							</div>
							<h1 className={styles["dsk-subtitle"]}><q>La innovación constante es el motor que impulsa el crecimiento y la relevancia en el mundo empresarial.</q></h1>
							<p className={styles["dsk-subtitle"]}>
								
								{" "}Jeff Bezos.
							</p>
							<div className={styles["dsk-image"]}>
								<img src={group_login} alt="Login" width={38} height={6} />
							</div>
						</div>
					</div>
					<div className="col-xs-12 col-md-5 mt-5 mb-5">
						{children}
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	)
}

export default BaseLayoutLogin