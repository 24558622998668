import { searchCustomer } from "services/actions/customerAction"
import { useDispatch, useSelector } from "react-redux"
import { validarRut } from "utils/ToolRUT/"
import { getPhone } from "utils/globals";
import { phone } from "phone";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useCustomer = (toast) => {

	const dispatch = useDispatch()
	const { companyDataBasic } = useSelector((state) => state.users)

	const searchCustomerByRUT = async (value, type) => {

		if (value.length > 0) {
		  const isValid = validarRut(value);
		  if (!isValid.status) {
			toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
			return false;
		  }
		  if (value === RUT_MESON) {
			toast.error("No se puede registrar con ese RUT", {
			  position: toast.POSITION.TOP_RIGHT,
			});
			return false;
		  }
		  const response = await dispatch(
			searchCustomer({ search_type: "rut_cliente", value, company_id: companyDataBasic?.id, customer_type: type })
		  );

		  let telefono = "";
		 
			if (
			  response.data?.phone_number !== null &&
			  response.data?.phone_number !== ""
			) {
			  telefono = getPhone(response.data?.phone_number, companyDataBasic);
			}
			return {
				rut: value,
				full_name: response?.data?.full_name ?? "",
				phone_number: telefono ?? "",
				email: response?.data?.email ?? "",
				address: response?.data?.address ?? ""
			}	 
		}
	};

	const handleBlurRUT = async (event, type) => {
		if (event.target.value.length > 0) {
		  return await searchCustomerByRUT(event.target.value, type);
		}
	  };

	const validatePhone = (value) => {
		if (
		  !phone(`${companyDataBasic?.country?.code}${value}`, {
			country: companyDataBasic?.country?.iso,
			validateMobilePrefix: false,
		  }).isValid
		) {
		  return "El número ingresado no es válido";
		}
	};



	return {
		searchCustomerByRUT,
        validatePhone,
        handleBlurRUT
	}
}