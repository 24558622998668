import IconInputPassword from "components/Password/IconInputPassword";
import { useFormPassword } from "hooks/Profile/useFormPassword"
import { useUtil } from "hooks/Utils/useUtil"
import styles from "styles/Profile.module.css";


const FormPassword = () => {

    const { formElement, state, actions } = useFormPassword()
    const {  switchShown: switchShownCurrent, shown: shownCurrent } = useUtil()
    const { switchShown: switchShownPass, shown:shownPass } = useUtil()
	const {  switchShown: switchShownRep, shown: shownRep } = useUtil()

    const { register,  handleSubmit, errors, onSubmit, getValues } = formElement
    const { isLoading } = state
    const { validatePassword } = actions


    return (
        <form className={styles["form-account"]} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
            <label htmlFor="passwordCurrent" className={`${styles["label-title"]} mb-0`}>Contraseña actual</label>
                <div className="p-relative">
                    <input 
                        type={shownCurrent ? 'text' : 'password'}  
                        className="form-control form__input"
                        id="passwordCurrent" 
                        name="passwordCurrent"
                        aria-describedby="passwordCurrent"
                        placeholder="Contraseña"
                        ref={register({
                            required: { value: true, message: "Requerido" },
                            minLength: { value: 9, message: "Mínimo 9 caracteres" },
                            maxLength: {
                              value: 15,
                              message: "Máximo 15 caracteres",
                            },
                            //eslint-disable-next-line
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
                              message:
                                "La contraseña debe tener: al menos 9 caracteres, 1 número, 1 caracter en minúscula, 1 caracter en mayúscula y 1 caracter especial",
                            },
                        })}
                        maxLength={15}
                    />
                    <IconInputPassword 
                        shown={shownCurrent}
                        switchShown={switchShownCurrent}
                />
                </div>
                {(errors?.passwordCurrent?.message && errors?.passwordCurrent?.message!=="")  && (
                    <p className="text-danger mb-2 w-100 text-left">
                        {errors?.passwordCurrent?.message}
                    </p>
                )}
            </div>
                
            <div className="form-group mb-4">
                <label htmlFor="password" className={`${styles["label-title"]} mb-0`}>Contraseña nueva</label>
                <div className="p-relative">
                    <input 
                        type={shownPass ? 'text' : 'password'} 
                        className="form-control form__input"
                        id="password" 
                        name="password"
                        aria-describedby="password"
                        placeholder="Contraseña"
                        ref={register({
                            required: { value: true, message: "Requerido" },
                            minLength: { value: 9, message: "Mínimo 9 caracteres" },
                            maxLength: {
                              value: 15,
                              message: "Máximo 15 caracteres",
                            },
                            validate: validatePassword,
                            //eslint-disable-next-line
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
                              message:
                                "La contraseña debe tener: al menos 9 caracteres, 1 número, 1 caracter en minúscula, 1 caracter en mayúscula y 1 caracter especial",
                            },
                          })}
                          maxLength={15}
                    />
                    <IconInputPassword 
                        shown={shownPass}
                        switchShown={switchShownPass}
                    />
                </div>
                {(errors?.password?.message && errors?.password?.message!=="")  && (
                    <p className="text-danger mb-2 w-100 text-left">
                        {errors?.password?.message}
                    </p>
                )}
            </div>
                
            <div className="form-group mb-4">
                <label htmlFor="repPassword" className={`${styles["label-title"]} mb-0`}>Repetir contraseña nueva</label>
                <div className="p-relative">
                    <input 
                        type={shownRep ? 'text' : 'password'} 
                        className="form-control form__input"
                        id="repPassword" 
                        name="repPassword"
                        aria-describedby="repPassword"
                        placeholder="Confirmar contraseña"
                        ref={register({
                            required: { value: true, message: "Requerido" },
                            minLength: { value: 8, message: "Mínimo 8 caracteres" },
                            maxLength: {
                              value: 15,
                              message: "Máximo 15 caracteres",
                            },
                            validate: (value) => {
                              if (value === getValues()["password"]) {
                                return true;
                              } else {
                                return "Las claves no coinciden";
                              }
                            },
                          })}
                          maxLength={15}
                    />
                    <IconInputPassword 
                        shown={shownRep}
                        switchShown={switchShownRep}
                    />
                </div>
                {(errors?.repPassword?.message && errors?.repPassword?.message!=="")  && (
                    <p className="text-danger mb-2 w-100 text-left">
                        {errors?.repPassword?.message}
                    </p>
                )}
            </div>
            <div className="form-group text-right">
                <button 
                    type="submit" 
                    className={`btn btn-secondary btn-save`}
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Guardar"}</button>
                </div>
        </form>
    )
}

export default FormPassword