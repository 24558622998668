const API = process.env.REACT_APP_API_URL;

const endPoints = {
  auth: {
    login: `${API}/auth/web/login`,
    verifiedLogin: `${API}/auth/web/verified-login`,    
    logout: `${API}/auth/web/logout`,
    bycode: `${API}/auth/web/client/code`,
    verifyAuthorized: `${API}/auth/verify-customer`,
  },
  consultationAndComplaint: {
    sendForm: `${API}/web/claims/send-form`,
    list: `${API}/web/claims`,
    getMessage: `${API}/web/claims/conversation`,
    sendRepley: `${API}/web/claims/send-repley`,
    updateMessage: `${API}/web/claims/message-status`
  },
  company: {
    config: (companyId) => `${API}/web/companies/${companyId}`,
  },
  formaPago: {
    list: `${API}/web/form-payments`,
  },
  transportOrder: {
    getOne: (order_number, company_id) => `${API}/web/transport-orders/${order_number}/${company_id}`,
    getListAll: (page, limit) => `${API}/web/transport-orders/all?page=${page}&limit=${limit}`,
    countPackage: `${API}/web/transport-orders/counted`,
  },
  withdrawals: {
    getListAll: (page, limit) => `${API}/web/packages/all?page=${page}&limit=${limit}`,
    previewRetiro: `${API}/web/withdrawals/tickets`,
    createRetiros: `${API}/web/withdrawals/create`,
    assignOrden: (id) =>`${API}/web/withdrawals/${id}/assign/orden`,    
  },
  
  customer: {
    update: `${API}/web/customers/updated`,
    password: `${API}/web/customers/password`,
    search: `${API}/web/customers`,
    add: `${API}/web/customers/create`,
  },
  retiros: {
    addRetiros: `${API}/web/withdrawals`,
    
    
    previewTicketRetiro: (id) => `${API}/web/packages/ticket/${id}`,
    
    printExcelPdf: `${API}/web/packages/download`,
    getInfoPackage: (numero_retiro) =>`${API}/web/packages/${numero_retiro}/search`,
    downloadInfo: `${API}/web/packages/informe`,
    
    
  },
  orders: {
    
    printExcelPdf: `${API}/web/transport-orders/download`,
    notification: (page, limit) => `${API}/web/transport-orders/notification?page=${page}&limit=${limit}`,
  },
  inovice: {
  	getInoviceClient: (page, limit) => `${API}/web/invoice?page=${page}&limit=${limit}`,
  	getInoviceCompany: (page, limit) => `${API}/web/invoice/company?page=${page}&limit=${limit}`
  },
  password: {
    customer: `${API}/auth/web/verify-customer`,
    forgot: `${API}/auth/web/password-reset`,
    code: `${API}/auth/web/password-code`,
    reset: `${API}/auth/web/password-new`
  },
  office: {
    list: (companyId) => `${API}/web/cities/${companyId}`,
  },
  
 
};

export default endPoints;