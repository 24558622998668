import { getLoading, searchOrden, setNumberOrder } from "services/actions/transportOrderAction"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

export const useSearchOrder = () => {
    
    const dispatch = useDispatch()
    const history = useHistory()
	const { dataTransportOrder, isLoadingSearch, messageSearch, searchOrderNumber } = useSelector((state) => state.transportOrders)
    const { userData } = useSelector((state) => state.users)
    
    const getDataInitial = async (order_number) => {
        dispatch(getLoading(true, "Espere unos segundos, se está consultando al orden de flete..."))
        const result = await dispatch(searchOrden(order_number))
      
        if(!result?.status){
            toast.error(result?.message, {position: toast.POSITION.TOP_RIGHT})
           setTimeout(() => {
            dispatch(getLoading(false, ""))
            history.push(`/transport-orders`);
          }, 1000);
        }
    }

    const searchRUTHeader =  async (order_number) => {
        if(order_number?.length!==0){
            history.push(`/transport-orders/${order_number}`)
        }        
	}

    const handleKeyDownOrderNumber = (event) => {
		if (event.keyCode === 13) {
            searchRUTHeader(searchOrderNumber)
        }
    };

    const searchNumberOrder = (value) => {
		dispatch(setNumberOrder(value))
    };



    const state = {
        isLoadingSearch,
        dataTransportOrder,
        messageSearch,
        searchOrderNumber,
        userData
    }

    const actions = {
        getDataInitial,
        handleKeyDownOrderNumber,
        searchNumberOrder
    }

    return {
        state,
        actions
    }
}