import { useEffect } from "react"
import Layout from "components/Layout/Layout";
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Dashboard.module.css";
import { Link } from "react-router-dom";
import FormSearchAll from "components/Withdrawals/ViewAll/FormSearchAll";
import { useDashboardOrder } from "hooks/TransportOrders/useDashboardOrder"
import ReactPaginate from 'react-paginate';

const Dashboard = () => {
	
    const { actions, state, formElement } = useDashboardOrder()
	
	const { allTransportOrders, pageCount, pageOffset } = state
	const { downloadSearch, handlePageChange, resetList } = actions

	useEffect(() => {
		resetList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout modulo="inicio">
			<div className="row  padding-menu-top">
                <FormSearchAll 
					typeSearch="orders" 
					itemBreadcrumb="Mis ordenes"
					urlBreadCrumb="/transport-orders"
					formElement={formElement}
				/>
				<div className="col-12 pt-5 flex-table">
                    <div className="table-responsive flex-grow-1">
                        <table className={`table table-hover ${styles["table-invoice"]} table-order-hover`}>
                            <thead>
                                <tr>
                                    <th scope="col">Nro. orden</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Origen</th>
                                    <th scope="col">Destino</th>
                                    <th scope="col">Tipo de envío</th>
                                    <th scope="col">Forma de pago</th>
                                    <th scope="col">Cant. bultos</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
										allTransportOrders?.map((item, index) => 
										<tr key={index}>
											<td>{item?.order_number}</td>
											<td>{item?.date_order_search}</td>
											<td>{item?.city_origin}</td>
											<td>{item?.city_destination}</td>
											<td>{item?.shipment_type}</td>
											<td>{item?.payment_form}</td>    
											<td>{item?.total_packs}</td>                                             
											<td>
												<span className={`badge ${styles["space-badge"]} ${styles[item?.state==="Entregada" ? "space-badge--green" : "space-badge--pink"]}`}>
													{item?.state}
												</span>
											</td>                                                
											<td>
												<Link to={`/transport-orders/${item?.order_number}`}>
													<button className={`btn btn-light ${styles["btn--purple"]}`}>Ver detalle</button>
												</Link>
											</td>
										</tr>
									)
								}
                            </tbody>
                        </table>
						{
							allTransportOrders?.length===0 && ( 
								<div className="rows-empty">
									<p  className="text-center">No hay registros disponibles</p>
								</div>
							)
						}
                    </div>
                    <div className={`w-100 fixed-btn pt-5`}>
						<div>
							<button 
								type="button"
								className="btn btn-outline-primary btn-size-33 mr-3 pl-5 pr-5 mb-3" 
								onClick={(event)=>downloadSearch(event, "pdf", "packages")} 
								disabled={allTransportOrders?.length===0 ? true : false}
							>
							Descargar PDF
							</button>

							<button 
								type="button"
								className="btn btn-outline-primary btn-size-33 pl-5 pr-5 mb-3"
								onClick={(event)=>downloadSearch(event, "excel", "packages")} 
								disabled={allTransportOrders?.length===0 ? true : false}
							>
							Descargar Excel
							</button>
						</div>
						<div>
							<ReactPaginate
								previousLabel="Anterior"
								nextLabel="Siguiente"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={handlePageChange}
								containerClassName="pagination"
								activeClassName="active"
								forcePage={pageOffset}
							/> 
						</div>
                    </div>
				</div>
               

			</div>
		</Layout>
	);

};

export default Dashboard;
