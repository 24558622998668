import { 
	FORM_PREORDEN,
	RESET_FORM_PREORDEN,
	NEXT_FORM_PREORDEN,
	SAVE_PACKAGES,
	LIST_RETIROS,
	SAVE_FORM_PREORDEN,
	CHANGE_MENU_OPTIONS,
	CHANGE_MENU_OPTIONS_RETIRO,

	LIST_INITIAL_DASHBOARD,
	LOADING_INITIAL_DASHBOARD,
	LOADING_INITIAL_RETIRO,
	LIST_INITIAL_RETIRO,
	DATA_INVOICE,
	SELECTED_MULTIPLE_DESTINATATION,
	FORM_PREORDEN_DESTINATION,
	FORM_PREORDEN_PACKAGE,
	RESET_DESTINATION,
	DATA_PACKAGE_ALL,
	DATA_ORDER_ALL,
	DATA_SEARCH_PACKAGE_ALL,
	GET_DATA_PACKAGE,

	DATA_PACKAGE_ALL_RETIRO,
	UPDATE_CONFIG_FORM_RETIRO,
	OPEN_DIALOG,
	COUNTED_ORDER_ALL
} from 'services/types/withdrawalType';
import { dataInitialState } from "data/dataInitialState"
const { listOptionsDashboard, listOptionsRetiro } = dataInitialState()

const INITIAL_STATE = {
	formPreOrden: {
		razon_social_remitente: "",
		rut_remitente: "",
		telefono_remitente: "",
		comuna_origen_id: "",
		direccion_retiro: "",
		email_remitente: "",
		observacion: "",
		multiple_destinatation: false,
		destinations: [],
		comuna_name: "",
		sucursal_name: ""
	},
	infoDestination: [],
	formViewActive: {
		title: "Quien envía",
		form: "receive"
	},
	listRetiros: [],
	printNroRetiro: 0,
	optionsDashboardMenu: listOptionsDashboard,
	listGeneral: listOptionsDashboard[0],
	isLoadingDashboard: false,
	listMyInvoice: [],
	listPackageSend: [],
	listPackagesAll: { isLoading: false, list: []},
	listOrdersAll: [],
	listSearchPackages: [],
	listNotificationPackages: [],
	isLoadingSearchPackages: false,
	isLoadingRetiro: false,
	menuOptionsListRetiro: listOptionsRetiro,
	listGeneralRetiro:null,
	infoPackage: {
		id: "",
		numero_retiro: "",
		razon_social_remitente: "",
		fecha: "",
		cant_preorden: "",
		comuna_name: "",
		sucursal_name: "",
		preorden: [],
		search: false
	},
	loadingInfoPackage: false,
	configFormData: [],
	showModalNewWithdral: false,

	listWithdrawals: [],
	listTransportOrders: [],
	countedOrders: {
		received: 0,
		realized: 0
	},
	listInvoices: []
};

const withdrawalReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_INITIAL_DASHBOARD:
			return {
				...state,
				optionsDashboardMenu: action.payload.menuOptionsList,
				//listGeneral: action.payload.listOptionSelected,
				isLoadingDashboard: action.payload.isLoadingDashboard
			}
		case OPEN_DIALOG:
			return {
				...state,
				showModalNewWithdral: action.payload,
			}
		case LOADING_INITIAL_DASHBOARD:
			return {
				...state,
				isLoadingDashboard: action.payload
			}
		case DATA_PACKAGE_ALL:
			return {
				...state,
				listWithdrawals: action.payload.packages,
			}
		case DATA_ORDER_ALL:
			return {
				...state,
				listTransportOrders: action.payload.orders,
			}
		case COUNTED_ORDER_ALL:
			return {
				...state,
				countedOrders: action.payload.data,
			}
			

		case CHANGE_MENU_OPTIONS:
			return {
				...state,
				listGeneral: action.payload.selected,
				optionsDashboardMenu: action.payload.menuOptionsList
			}
		case LIST_INITIAL_RETIRO:
			return {
				...state,
				menuOptionsListRetiro: action.payload.optionsRetiro,
				listGeneralRetiro: action.payload.listOptionSelected,
				isLoadingRetiro: action.payload.isLoadingRetiro
			}
		case LOADING_INITIAL_RETIRO:
			return {
				...state,
				isLoadingRetiro: action.payload.isLoadingRetiro,
				menuOptionsListRetiro: action.payload.menuOptionsList,
				listGeneralRetiro: action.payload.listOptionSelected,
			}

		case DATA_INVOICE:
			return {
				...state,
				listMyInvoice: action.payload.options,
			}

		case FORM_PREORDEN:
			return {
				...state,
				formPreOrden: action.payload.formOrden
			}
		case RESET_FORM_PREORDEN:
			return {
				...state,
				formPreOrden: action.payload.formOrden,
				formViewActive: action.payload.formViewActive
			}
		case NEXT_FORM_PREORDEN:
			return {
				...state,
				formViewActive: action.payload.formViewActive
			}
		case SAVE_PACKAGES:
			return {
				...state,
				infoDestination: action.payload.destionation
			}
		case LIST_RETIROS:
			return {
				...state,
				listRetiros: action.payload.list
			}
		case SAVE_FORM_PREORDEN:
			return {
				...state,
				formPreOrden: action.payload.formOrden,
				printNroRetiro: action.payload.printNroRetiro,
				optionsDashboardMenu: action.payload.optionsDashboardMenu,
				menuOptionsListRetiro: action.payload.menuOptionsListRetiro,
				configFormData: action.payload.configFormData
			}
		
		case CHANGE_MENU_OPTIONS_RETIRO:
			return {
				...state,
				listGeneralRetiro: action.payload.selected,
				menuOptionsListRetiro: action.payload.menuOptionsList
			}
		case SELECTED_MULTIPLE_DESTINATATION:
			return {
				...state,
				formPreOrden:{
					...state.formPreOrden,
					multiple_destinatation: action.payload.multiple
				}
			}
		case FORM_PREORDEN_DESTINATION:
			return {
				...state,
				infoDestination: action.payload.destination,
				configFormData: action.payload.configForm
			}
		case FORM_PREORDEN_PACKAGE:
			return {
				...state,
				formPreOrden: action.payload.formOrden
			}
		case RESET_DESTINATION:
			return {
				...state,
				infoDestination: action.payload.destination
			}
		
		case DATA_SEARCH_PACKAGE_ALL:
			return {
				...state,
				listSearchPackages: action.payload.options,
				isLoadingSearchPackages: action.payload.isLoading
			}
		case GET_DATA_PACKAGE:
			return {
				...state,
				infoPackage: action.payload.infoPackage,
				loadingInfoPackage: action.payload.loadingInfoPackage
			}
		case DATA_PACKAGE_ALL_RETIRO:
			return {
				...state,
				menuOptionsListRetiro: action.payload.packages
			}
		case UPDATE_CONFIG_FORM_RETIRO:
			return {
				...state,
				configFormData: action.payload
			}
		default: return state;
	}
};

export default withdrawalReducer;