import TablePackage from "components/Withdrawals/Forms/TablePackage";
import styles from "styles/Retiro.module.css";
import { usePackage } from "hooks/WithDrawals/usePackage"
import { onPaste, validateOnlyNumber } from '../../../utils/validationInput';
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format";


const FormPackage = ({ toast }) => {

	const { onSubmit, register, handleSubmit, errors, 
		lastFormPreOrden, infoDestination, deleteRow, editRow, nextForm, control,
		nroPeso, setNroPeso,  nroLargo, setNroLargo, nroAncho, setNroAncho, nroAlto, setNroAlto,
		getValues } = usePackage(toast)
	
		const validateBulto = (event) => {
   
			if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
			  toast.error("Debe ingresar la cantidad de bultos", {position: toast.POSITION.TOP_RIGHT})
			  return false
			}
		  }

	return (
		<section className="mt-4">
			<form onSubmit={handleSubmit(onSubmit)}>
			   	<div className="row mb-4">
				   <div className="col-12 col-md-6 mb-3">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="bulto">Bulto</label>
				      	<input 
				      		type="text" 
				      		className="form-control form__withadral_input" 
				      		id="bulto" 
				      		name="bulto"
				      		ref={register({required: {value: true, message: 'Requerido'}})}
							maxLength="2"
							onPaste={(event) => onPaste(event)}				
							onKeyPress={(event) => validateOnlyNumber(event)}
							onBlur={(event) => validateBulto(event)}
				      	/>
				      	{errors.bulto && (
					    	<p className="text-danger mb-4">
					      		{errors?.bulto?.message}
					    	</p>
					    )}
				    </div>
					<div className="col-12 col-md-6">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="peso">Peso total (Kg)</label>
						  <Controller
							control={control}
							name="peso"  
							rules={{required: {value: true, message: 'Requerido'}}}
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control form__withadral_input"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroPeso}
									maxLength="10"
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setNroPeso(values.floatValue);
									}}
								/>
							)}
							autoComplete="off"
						/>
				      	{errors.peso && (
					    	<p className="text-danger mb-4">
					      		{errors?.peso?.message}
					    	</p>
					    )}
				    </div>
			  	</div>
			  	<div className="row mb-4">
				    <div className="col-12 col-md-6 mb-3">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="largo">Largo (m)</label>
						  <Controller
							control={control}
							name="largo"  
							rules={{required: {value: true, message: 'Requerido'}}}
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control form__withadral_input"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroLargo}
									maxLength="10"
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setNroLargo(values.floatValue);
									}}
								/>
							)}
							autoComplete="off"
						/>
				      	{errors.largo && (
					    	<p className="text-danger mb-4">
					      		{errors?.largo?.message}
					    	</p>
					    )}
				    </div>
				    <div className="col-12 col-md-6">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="ancho">Ancho (m)</label>
						<Controller
							control={control}
							name="ancho"  
							rules={{required: {value: true, message: 'Requerido'}}}
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control form__withadral_input"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroAncho}
									maxLength="10"
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setNroAncho(values.floatValue);
									}}
								/>
							)}
							autoComplete="off"
						/>
				      	{errors.ancho && (
					    	<p className="text-danger mb-4">
					      		{errors?.ancho?.message}
					    	</p>
					    )}
				    </div>
			  	</div>
				<div className="row mb-3">
					<div className="col-12 col-md-6">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="alto">Alto (m)</label>
						  <Controller
							control={control}
							name="alto"  
							rules={{required: {value: true, message: 'Requerido'}}}
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control form__withadral_input"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroAlto}
									maxLength="10"
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setNroAlto(values.floatValue);
									}}
								/>
							)}
							autoComplete="off"
						/>
				      	{errors.alto && (
					    	<p className="text-danger mb-4">
					      		{errors?.alto?.message}
					    	</p>
					    )}
				    </div>
			  	</div>
			  	<div className="row mb-3 d-flex justify-content-end">
				    <div className="col-5">
				    	<button className="btn btn-outline-primary btn-block" type="submit">
				            Agregar paquete
				        </button>
				    </div>
			  	</div>
		  	</form>
			
			<section>
			<table className="table table-bordered table-package">
				<thead>
					<tr>
						<th scope="col">Bulto</th>
						<th scope="col">Peso total</th>
						<th scope="col">Largo</th>
						<th scope="col">Ancho</th>
						<th scope="col">Alto</th>						
						<th scope="col" style={{"width":"8rem"}}></th>
					</tr>
				</thead>
				<tbody>
					{
						infoDestination[0].listPackages.length>0 && infoDestination[0].listPackages.map((item, index) => 
							<TablePackage 
								key={index}
								{...item}
								index={index}
								deleteRow={deleteRow}
								editRow={editRow}
								register={register}
								control={control}
								getValues={getValues}
							/>
						)
					}
					{
						infoDestination[0].listPackages.length===0 && (
							<tr>
								<td colSpan={6}>No hay registros</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</section>			 
		  	<div className={styles["btn-modal"]}>
			  	<button 
					type="button"
			  		className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
			  		onClick={()=>lastFormPreOrden("destination")}
			  	>
		            Atrás
		        </button>
		        <button 
		        	type="button"
		        	className="btn btn-secondary btn-size-33 btn-block mt-0" 
		        	onClick={(event)=>nextForm(event, "aditional")}
		        >
		            Siguiente
		        </button>
			</div>
			<div className="row mb-3">
				  	<div className={`col ${styles["item-container"]}`}>
				  		<div className={styles["item-disabled"]}></div>
				  		<div className={styles["item-selected"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  	</div>
			</div>
		</section>
	);
};

export default FormPackage;