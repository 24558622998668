import { getLoadConfigCompany } from "services/actions/userAction"
import { useDispatch, useSelector } from "react-redux"
import { getDataInitialPago } from "services/actions/formaPagoAction"


export const useCompany = () => {

	const dispatch = useDispatch()
	const { companyDataBasic } = useSelector((state) => state.users)
	const { listFormaPagos } = useSelector((state) => state.formaPagos)

	const getBasicCompany = async () => {
		await dispatch(getLoadConfigCompany())

		if(listFormaPagos?.length===0){
			await dispatch(getDataInitialPago())
		}		
	}


	return {
		getBasicCompany,
        companyDataBasic
	}
}