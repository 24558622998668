import Layout from "components/Layout/Layout";
import ItemOffice from "components/Office/ItemOffice"
import LoadingOffice from "components/Office/LoadingOffice";
import stylesOffice from "styles/Office.module.css";
import { useOffice } from "hooks/Office/useOffice";


const MisOficinas = () => {
	
	const { state, actions } = useOffice()
	const { visiblePageOffice, sucursalSelected, loadingComuna, urlMap } = state
	const { handleViewMapa } = actions


	return (
		<Layout modulo="mis-retiros">
			<div className="row">
				<div className={`col-md-12 col-lg-5`}>
						<section>
							<p className={stylesOffice["dsk-title"]}>Ubica la oficina</p>
							<p className={stylesOffice["dsk-subtitle"]}>más cercana a ti</p>
						</section>
					{!loadingComuna && (
						<div>
							
							{
								visiblePageOffice?.length>0 && 
								visiblePageOffice?.map((item, key) => 
									<ItemOffice key={key} info={item} handleViewMapa={handleViewMapa} />
								)
							}
						</div>
					)}
					{!loadingComuna && sucursalSelected?.comunas?.length===0 && (
						<div className="pt-5">
							<p className={stylesOffice["title"]}>No hay oficinas disponibles</p>
						</div>
					)}
					{loadingComuna && <LoadingOffice  /> }
				</div>
				{
					(!loadingComuna && sucursalSelected!==null) && (
						<div className={`col-md-12 col-lg-7 ${stylesOffice["dsk-img"]}`}>
							<div className="oficina-map">
								{
									urlMap!=="" ?
									<iframe title="Mapa" src={urlMap} width="100%" height="311" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
									: <p className={`${stylesOffice["item-info__text"]}`}>Mapa No disponible</p>
								}								
							</div>
						</div>
					)
				}
			</div>
		</Layout>
	);

};

export default MisOficinas;