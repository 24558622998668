import stylesOffice from "styles/Office.module.css";
import sucursales_img from "assets/images/sucursal.png";


const ItemOffice = ({ info, handleViewMapa }) => {

	return (
		<>
			<section className={stylesOffice["container"]}>
				<p className={stylesOffice["title"]}>{info.name}</p>
				<p className={stylesOffice["address"]}>{info.address}</p>
				<div className={stylesOffice["item"]}>
					<img src={sucursales_img} alt="comuna" />
					<div className={stylesOffice["item-info"]}>
						<div className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-email"]}`}>
							<a href={`mailto:${info.email}`}>{info.email}</a>
						</div>
						<p className={`${stylesOffice["item-info__text"]} ${stylesOffice["icon-telephone"]}`}>{info?.telephone_numbers}</p>
						<div 
							className={`${stylesOffice["item-info__text"]} mt-2 ${stylesOffice["item-info__link"]}  ${stylesOffice["icon-map"]}`}
							onClick={(event) => handleViewMapa(event, info.url_map)}
						>
							<a href="!#">Ver Mapa</a>
						</div>
					</div>
				</div>
			</section>
			{
				info?.schedule!==null && info?.schedule!=="" && (
					<section>
						<p className={`${stylesOffice["title"]} ${stylesOffice["icon-time"]}`}>Horario</p>
						<div className={stylesOffice["info-address"]}>
							<p className={`${stylesOffice["item-info__text"]}`}>{info?.schedule}</p>
							
						</div>
					</section>
				)
			}
			
		</>
	)
}

export default ItemOffice