import { useEffect } from "react"
import BaseLayoutLogin from "components/Layout/BaseLayoutLogin";
import IconInputPassword from "components/Password/IconInputPassword";
import delivery_mobile from "assets/images/delivery-mobile.svg";
import circle_success from "assets/images/circle-success.svg";
import { validateOnlyLetter, handlePasteOnlyLetter, validateOnlyNumber, handlePasteOnlyNumber,
	onPaste
 } from "utils/globals";
import styles from "styles/Login.module.css";
import { Link } from "react-router-dom";
import { useRegisterCustomer } from "hooks/Login/useRegisterCustomer"
import { useUtil } from "hooks/Utils/useUtil"
import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'


export default function Register () {

	const { state, formElement, actions } = useRegisterCustomer()
	const { switchShown: switchShownPass, shown:shownPass } = useUtil()
	const {  switchShown: switchShownRep, shown: shownRep } = useUtil()

	const { onSubmit, register, handleSubmit, errors, control, getValues, setValue} = formElement
	const { isLoading, isSuccess, isDisabled, companyDataBasic, isSpinner, isReadOnlyBasic, tokenUser, isSuccessToken, 
		isReadOnlyRut, isReadOnlyPass } = state
	const { setIsDisabled, getVerifiedToken, onKeyDownFactura, validatePhone, validatePassword } = actions

	useEffect(() => {
		getVerifiedToken()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<BaseLayoutLogin>

		{isSpinner && (	
			<div className={styles["form-login"]}>
				<div className="text-center">
						<div className="spinner-grow" role="status">
						<span className="sr-only">Loading...</span>
					</div>
					<div className="spinner-grow" role="status">
						<span className="sr-only">Loading...</span>
					</div>
					<div className="spinner-grow" role="status">
						<span className="sr-only">Loading...</span>
					</div>
					<div className="spinner-grow" role="status">
						<span className="sr-only">Loading...</span>
					</div>
					<div className="spinner-grow" role="status">
						<span className="sr-only">Loading...</span>
					</div>
					<div className="spinner-grow" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
				<p className={styles["text-account"]}>Estamos verificando la información, espere unos segundos...</p>
			</div>
		)}
		{
			(!isSpinner && isSuccessToken) && (
				<form className={styles["form-login"]} onSubmit={handleSubmit(data=>onSubmit(data, tokenUser))} autoComplete="off">
					<div className={`${styles["group-image__logo"]} mb-5`}>
						<img src={companyDataBasic?.logo} alt="logo" width={147} height={96} />
					</div>
					<div className={styles["group-image"]}>
						<img src={delivery_mobile} alt="Login" width={230} height={128} />
					</div>
					<input type="email" name="email" value="something@gmail.com" onChange={()=>null} className="label-hidden"/>
					
					{ !isSuccess && (
						<section className={styles["form-section"]}>
							<div className={`form-group mb-4`}>
								{
									!isReadOnlyRut && (
										<Controller
											render={(props) => (
												<MaskedInput
													mask={RutTextMask}
													className="form-control form__input" 
													placeholder="Ej: 12.345.678-9"
													id="rut" 
													name="rut"
													value={props.value}
													maxLength={12}
													onChange={(value)=>{
														props.onChange(value)
														setValue(value.target.value ?? "");
													}}
													readOnly={isReadOnlyRut}
													onKeyDown={(event) =>onKeyDownFactura(event)}
												/>
											)}
											control={control}
											name="rut"  
											rules={{required: {value: true, message: 'Requerido'}}}
										/>
									)
								}
								{
									isReadOnlyRut && (
										<input 
											type="text" 
											className="form-control form__input"
											id="rut" 
											name="rut"
											aria-describedby="rut"
											placeholder="Ej: 12.345.678-9"
											ref={register({
												required: { value: true, message: "Requerido" },
												minLength: { value: 5, message: "Mínimo 5 caracteres" },
											})}
											maxLength={12}
											readOnly={isReadOnlyRut}
										/>
									)
								}
								
								{(errors?.rut?.message && errors?.rut?.message!=="")  && (
									<p className="text-danger w-100 text-left">
										{errors?.rut?.message}
									</p>
								)}
							</div>
							
						<div className={`form-group mb-4`}>
							<input 
								type="text" 
								className="form-control form__input text-uppercase"
								id="fullname" 
								name="fullname"
								aria-describedby="fullname"
								placeholder="Nombre y Apellido"
								ref={register({
									required: { value: true, message: "Requerido" },
									minLength: { value: 5, message: "Mínimo 5 caracteres" },
								})}
								onKeyPress={(event) => validateOnlyLetter(event)}
								onPaste={(event) => handlePasteOnlyLetter(event)}
								maxLength={100}
								readOnly={isReadOnlyBasic}
							/>
							{(errors?.fullname?.message && errors?.fullname?.message!=="")  && (
								<p className="text-danger w-100 text-left">
									{errors?.fullname?.message}
								</p>
							)}	
						</div>
									
						<div className={`form-group mb-4`}>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="telephone">{companyDataBasic?.country?.code}</span>
								</div>
								<input 
									type="text" 
									className="form-control form__input"
									id="telephone" 
									name="telephone"
									aria-describedby="telephone"
									placeholder="Número de teléfono"
									ref={register({
										minLength: { value: 9, message: "Mínimo 9 dígitos" },
										required: { value: true, message: "Requerido" },
										//eslint-disable-next-line
										pattern: {
										  value: /^([0-9]+)$/,
										  message: "Teléfono inválido",
										},
										validate: validatePhone,
									  })}
									  autoComplete="off"
									  onKeyPress={(event) => validateOnlyNumber(event)}
									  onPaste={(event) => handlePasteOnlyNumber(event)}
									readOnly={isReadOnlyBasic}
								/>
							</div>	
							{(errors?.telephone?.message && errors?.telephone?.message!=="")  && (
								<p className="text-danger w-100 text-left">
									{errors?.telephone?.message}
								</p>
							)}				
						</div>
						
						<div className={`form-group mb-4`}>
							<input 
								type="email" 
								className="form-control form__input"
								id="r_email" 
								name="r_email"
								autoComplete="off"
								aria-describedby="r_email"
								placeholder="Correo electrónico"
								ref={register({
									//eslint-disable-next-line
									pattern: {
									  value:
										/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
									  message: "Email inválido",
									},
									required: { value: true, message: "Requerido" },
									minLength: { value: 5, message: "Mínimo 5 caracteres" },
								  })}
								  //defaultValue={datos?.email || ""}
								  onPaste={(event) => onPaste(event)}
								  readOnly={true}
							/>
							{(errors?.r_email?.message && errors?.r_email?.message!=="")  && (
								<p className="text-danger w-100 text-left">
									{errors?.r_email?.message}
								</p>
							)}
						</div>
						
						<div className={`form-group mb-4`}>
							<input 
								type="text" 
								className="form-control form__input"
								id="r_address" 
								name="r_address"
								aria-describedby="r_address"
								placeholder="Dirección"
								ref={register({
									required: { value: true, message: "Requerido" },
									minLength: { value: 5, message: "Mínimo 5 caracteres" },
								  })}
								readOnly={isReadOnlyBasic}
							/>
							{(errors?.r_address?.message && errors?.r_address?.message!=="")  && (
								<p className="text-danger w-100 text-left">
									{errors?.r_address?.message}
								</p>
							)}
						</div>
						
						<div className={`form-group mb-4`}>
							<div className="p-relative">
								<input 
									type={shownPass ? 'text' : 'password'} 
									className="form-control form__input"
									id="password" 
									name="password"
									aria-describedby="password"
									placeholder="Contraseña"
									ref={register({
										required: { value: true, message: "Requerido" },
										minLength: { value: 9, message: "Mínimo 9 caracteres" },
										maxLength: {
										value: 15,
										message: "Máximo 15 caracteres",
										},
										validate: validatePassword,
										//eslint-disable-next-line
										pattern: {
										value:
											/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
										message:
											"La contraseña debe tener: al menos 9 caracteres, 1 número, 1 caracter en minúscula, 1 caracter en mayúscula y 1 caracter especial",
										},
									})}
									maxLength={15}
									readOnly={isReadOnlyPass}
								/>
								{
									!isReadOnlyPass && (
										<IconInputPassword 
											shown={shownPass}
											switchShown={switchShownPass}
										/>
									)
								}
							</div>
							{(errors?.password?.message && errors?.password?.message!=="")  && (
								<p className="text-danger mb-2 w-100 text-left">
									{errors?.password?.message}
								</p>
							)}							
						</div>
						
						<div className={`form-group mb-4`}>
							<div className="p-relative">
								<input 
									type={shownRep ? 'text' : 'password'} 
									className="form-control form__input"
									id="repPassword" 
									name="repPassword"
									aria-describedby="repPassword"
									placeholder="Confirmar contraseña"
									ref={register({
										required: { value: true, message: "Requerido" },
										minLength: { value: 8, message: "Mínimo 8 caracteres" },
										maxLength: {
										value: 15,
										message: "Máximo 15 caracteres",
										},
										validate: (value) => {
										if (value === getValues()["password"]) {
											return true;
										} else {
											return "Las claves no coinciden";
										}
										},
									})}
									maxLength={15}
									readOnly={isReadOnlyPass}
								/>
								{
									!isReadOnlyPass && (
										<IconInputPassword 
											shown={shownRep}
											switchShown={switchShownRep}
										/>
									)
								}
							</div>
							{(errors?.repPassword?.message && errors?.repPassword?.message!=="")  && (
								<p className="text-danger mb-2 w-100 text-left">
									{errors?.repPassword?.message}
								</p>
							)}							
						</div>
						
						<div className={styles["group-check--term"]}>
							<div className={`form-group p-relative ${styles["form-group-check"]} mt-0`}>
								<input 
									type="checkbox" 
									className={styles["form-check"]} 
									id="recordarme" 
									onChange={() => setIsDisabled(!isDisabled)}
									name="recordarme" 
									defaultChecked={isDisabled}
									disabled={isReadOnlyPass}
								/>
								<label className={styles["form-check-label"]} htmlFor="recordarme">Estoy de acuerdo con los Terminos y Condiciones</label>
							</div>
						</div>
						<div className="form-group">
							<button 
								type="submit" 
								className="btn btn-primary btn-block mb-4 btn-claim"
								disabled={isLoading || !isDisabled || isReadOnlyPass}
							>{isLoading ? "Procesando..." : "Registrarse"}</button>
						</div>
						</section>
					)}
					{ isSuccess && (
						<section>
							<div className={`form-group`}>
								<div className={styles["mx-auto"]}>
									<img src={circle_success} alt="Login" width={230} height={128} />
								</div>
								<p className={styles["text-account"]}>Se ha registrado <br/> exitosamente</p>
							</div>
							
							<div className="form-group">
								<Link to="/"
									className="btn btn-primary btn-block mb-4 pt-3 btn-claim"
								>Iniciar sesión</Link>
							</div>
						</section>
					)}
				</form>	
			)
		}
		{
			(!isSpinner && !isSuccessToken) && (
				<div className={styles["form-login"]}>
					<div className={`${styles["group-image__logo"]} mb-5`}>
						<img src={companyDataBasic?.logo} alt="logo" width={147} height={96} />
					</div>
					<div className={`form-group`}>
						<div className={styles["mx-auto"]}>
							<img src={circle_success} alt="Login" width={230} height={128} />
						</div>
						<p className={styles["text-account"]}>Su enlace se encuentra vencido, <br/>comunique con la empresa {companyDataBasic?.legal_name}.</p>
					</div>
					
					<div className="form-group w-100">
						<Link to="/"
							className="btn btn-primary btn-block mb-4 pt-3 btn-claim"
						>Iniciar sesión</Link>
					</div>
				</div>
			)
		}
		</BaseLayoutLogin>
	);
};