import completed from "assets/images/completed.png"
import incompleted from "assets/images/incompleted.png"
import arrow_status from "assets/images/arrow-status.png"
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Dashboard.module.css";
import icon_inbox from "assets/images/icon-inbox.svg";

const DashboardOrder = ({ listTransportOrders, isLoadingDashboard }) => {


	
	return (
		<section className="w-100">
            <section className={styles["space-container"]}>
                <p className={styles["space-title"]}>Mis órdenes</p>
                <Link to={`/transport-orders/search`}>
                    <button className={`btn btn-primary ${styles["space-btn"]}`}>Ver historial</button>
                </Link>
            </section>
            <section className={styles["space-item"]}>
                {
                    !isLoadingDashboard && listTransportOrders?.map((orders, index) => (
                        <div className={`${styles["space-item__container"]} pt-3 pb-2`} key={index}>
                            <div className={`${styles["space-info"]} ${styles["space-item_info"]}`}>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Nro. orden:</b>  {orders?.order_number}</p>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Fecha:</b> {orders?.date_order_search}</p>
                            </div>
                            <div className={`${styles["space-status"]} ${styles["space-item_state"]}`}>
                                <div className={styles["space-status__container"]}>
                                    <img src={orders?.status_pending_initial ? completed : incompleted} alt="En despacho" />
                                    <p className={styles["space-status__title"]}>En despacho</p>
                                </div>
                                <div className={styles["space-status__row"]}>
                                    <img src={arrow_status} alt="Siguiente" />
                                </div>
                                <div className={styles["space-status__container"]}>
                                    <img src={orders?.status_transit ? completed : incompleted} alt="Tránsito" />
                                    <p className={styles["space-status__title"]}>Tránsito</p>
                                </div>
                                <div className={styles["space-status__row"]}>
                                    <img src={arrow_status} alt="Siguiente" />
                                </div>
                                <div className={styles["space-status__container"]}>
                                    <img src={orders?.status_delivery_pending ? completed : incompleted} alt="Por entregar" />
                                    <p className={styles["space-status__title"]}>Por entregar</p>
                                </div>
                                <div className={styles["space-status__row"]}>
                                    <img src={arrow_status} alt="Siguiente" />
                                </div>
                                <div className={styles["space-status__container"]}>
                                    <img src={orders?.status_delivered ? completed : incompleted} alt="Entregada" />
                                    <p className={styles["space-status__title"]}>Entregada</p>
                                </div>
                            </div>
                            <div className={`${styles["space-info"]} ${styles["space-item_info"]}`}>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Origen:</b>  {orders?.city_origin}</p>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Destino:</b>  {orders?.city_destination}</p>
                            </div>
                            <div className={`${styles["space-link--btn"]} ${styles["space-item_btn"]}`}>
                                <Link to={`/transport-orders/${orders?.order_number}`}>
                                    <button className={`btn btn-light ${styles["btn--purple"]}`}>Ver detalle</button>
                                </Link>
                            </div>
                        </div>
                    ))
                }

                {
                    isLoadingDashboard && Array.from([1,2,3,4,5])?.map(x => 
                        <div className={`${styles["space-item__container"]} pt-3 pb-2`} key={x}>
                            <div className={styles["space-info"]}>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>No. de orden:</b>  <Skeleton width={70} count={1} /></p>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Fecha:</b> <Skeleton width={70} count={1} /></p>
                            </div>
                            <div className={styles["space-info"]}>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Origen:</b>  <Skeleton width={70} count={1} /></p>
                                <p className={`${styles["space-item__title"]} mb-1`}><b>Destino:</b>  <Skeleton width={70} count={1} /></p>
                            </div>
                            <div className={styles["space-link--btn"]}>
                                <Skeleton width={70} count={1} />
                            </div>
                        </div>
                    )
                }
                
                {
                    !isLoadingDashboard && listTransportOrders?.length===0 && (
                        <div className={styles["options-not-records"]}>
                            <div><img src={icon_inbox} alt="No hay registros" /></div> 
                            <p className={styles["item-text__subtitle-fact"]}>
                                No hay órdenes disponibles
                            </p>
                        </div>
                    )
                }
            </section>
        </section>
	);

};

export default DashboardOrder;
