import { useState, forwardRef, useRef } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import styles from "styles/Retiro.module.css";
import { BiCalendar } from "react-icons/bi";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"
import { isAfter, getDateYMD, isBefore, isSame } from '../../../utils/formatFecha';
import dayjs from 'dayjs';
import { toast } from 'react-toastify'

registerLocale('es', es)

const FormSearchAll = ({ typeSearch, itemBreadcrumb, urlBreadCrumb, formElement }) => {

	const ref = useRef();
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())

	const { handleSubmit, onSubmit, control, isLoading } = formElement

	const validateStartDateBlur = () => {
		const dateStart = getDateYMD(dayjs(startDate).format('DD/MM/YYYY'))

		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			return false
		}

		if (!isSame(dateStart.dateSelected, endDate) && isAfter(dateStart.dateSelected, endDate)) {
			toast.error('La fecha inicial no puede ser superior a la fecha final', {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			setEndDate(new Date())
			return false
		}
	}

	const validateEndDateBlur = () => {
		
		const dataEnd = getDateYMD(dayjs(endDate).format('DD/MM/YYYY'))

		if(dataEnd.error){
			toast.error(dataEnd.message, {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}

		if (!isSame(dataEnd.dateSelected, dayjs(startDate).format('YYYY-MM-DD')) && isBefore(dataEnd.dateSelected, startDate)) {
			toast.error('La fecha final no puede ser inferior a la fecha inicial', {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}
	}

	
	const ExampleCustomInputFrom = forwardRef(
		({ value, onClick }, ref) => (
			<div className="input-group mb-3">
				<input 
					type="text" 
					className="form-control date-from" 
					placeholder="" 
					aria-label="Example text with button addon" 
					aria-describedby="button-addon1"
					name="fecha_orden_from"
					id="fecha_orden_from"
					value={value} 
					readOnly
				></input>
				<div className="input-group-prepend">
					<button className="btn btn-secondary date-from__calendar" type="button" id="button-addon1" onClick={onClick} ref={ref}>
						<BiCalendar />
					</button>
				</div>
			</div>			
		),
	);

	const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
		<div className="input-group mb-3">
				<input 
					type="text" 
					className="form-control date-from" 
					placeholder="" 
					aria-label="Example text with button addon" 
					aria-describedby="button-addon1"
					name="fecha_orden_to"
					id="fecha_orden_to"
					value={value} 
					readOnly
				></input>
				<div className="input-group-prepend">
					<button className="btn btn-secondary date-from__calendar" type="button" id="button-addon1" onClick={onClick} ref={ref}>
						<BiCalendar />
					</button>
				</div>
			</div>
	  ));



	return (
		<div className={`col-12 ${styles["d-form"]}`}>
			<div className={`${styles["breadcrumb"]} ${styles["d-form__breadcrumb"]}`}>
				<Link to="/transport-orders">
					<button className={`btn btn-outline-primary ${styles["btn-atras"]}`}>Atrás</button>
				</Link>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					<li className="breadcrumb-item"><Link to={urlBreadCrumb}>Inicio</Link></li>
					<li className="breadcrumb-item active" aria-current="page">{itemBreadcrumb}</li>
					</ol>
				</nav>
			</div>
			<div className={styles["form-main__search"]}>
				<form id="frmBusquedaGlobal" className={styles["d-form"]}
					onSubmit={handleSubmit(data => onSubmit(data, typeSearch))}
				>
					<div className={`form-group mr-3 ${styles["form-group__input"]} ${styles["d-form__group"]}`}>
						<label className={`form-label ${styles["form-search__label"]}`}>Desde</label>
						<Controller
					            control={control}
					            name="from_date"
					            defaultValue={endDate}
					            render={(props) => (
					              <DatePicker
					                dateFormat="dd/MM/yyyy"
					                placeholderText="Select date"
					                onChange={(e) => {
					                	props.onChange(e)
					                	setStartDate(e)
					                }}
					                selected={startDate}
					                className="form-control"
					                id="from_date"
					                maxDate={new Date()}
									locale="es"
									onCalendarClose={() => validateStartDateBlur()}
									customInput={<ExampleCustomInputFrom ref={ref}/>}
					              />
					            )}
					          />
					</div>
					<div className={`form-group ${styles["form-group__input"]} ${styles["d-form__group"]}`}>
						<label className={`form-label ${styles["form-search__label"]}`}>Hasta</label>
						<Controller
							control={control}
							name="to_date"
							defaultValue={endDate}
							render={(props) => (
								<DatePicker
								dateFormat="dd/MM/yyyy"
								placeholderText="Select date"
								onChange={(e) => {
									props.onChange(e)
									setEndDate(e)
								}}
								selected={endDate}
								className="form-control"
								id="to_date"
								maxDate={new Date()}
								locale="es"
								onCalendarClose={() => validateEndDateBlur()}
								customInput={<ExampleCustomInputTo ref={ref}/>}
								/>
							)}
							/>
					</div>
					<div className={`form-group ${styles["form-group__btn"]}`}>
						<button 
							type="submit"
							className={`btn btn-primary ${styles["btn-search__form"]} btn-search`} 
							disabled={isLoading}
						>
							{ isLoading ? 'Buscando...' : 'Buscar' }
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default FormSearchAll