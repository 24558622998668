import { useState } from "react"
import { validateLoginUser, logout, searchClientByCode, loginUser } from "services/actions/userAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { useUtil } from "hooks/Utils/useUtil";
import { toast } from 'react-toastify';

export const useLogin = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ listCodeInternal, setListCodeInternal ] = useState(false)
	const { register, handleSubmit, errors, control, setError, getValues } = useForm()
	const { userData } = useSelector((state) => state.users)

	const { handleBlurRut, shown, switchShown  } = useUtil()
	const [ dialogInternalCode, setDialogInternalCode ] = useState(false)
	const [ listDataUser, setListDataUser ] = useState([])
	const [ textDialog, setTextDialog ] = useState("")
	

	const onSubmit = async (data, event) => {
		try{
			setIsLoading(true)
			let body = {
				rut: data.rut,
				password: data?.password,
			}

			let response = await dispatch(validateLoginUser(body))
			
			setIsLoading(false)
			
			if(response.status){
				if(response.data!==null && response.data?.length>1){
					setIsLoading(false)
					setListDataUser(listDataUser => response.data)
					setDialogInternalCode(true)
					setTextDialog(textDialog => response.textDialog)
				}else{
					const company =  response.data[0];
					body = {
						...body,
						internal_number: company?.accountNumbers?.length>0 ? company?.accountNumbers[0].name : null,
						company_id: company?.id,
					}
					let result = await dispatch(loginUser(body))
		
					setIsLoading(false)
					if(result.status){
						toast.success(result.message, {position: toast.POSITION.TOP_RIGHT})
						history.push('/transport-orders')
					}else{
						toast.error(result.message, {position: toast.POSITION.TOP_RIGHT})
						return false
					}
				}
			}else{
				setIsLoading(false)
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
				return false
			}

		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const logoutUser = async (event) => {
		event.preventDefault()
		const response = await dispatch(logout())
		if(response.status){
			history.push('/')
            window.location.reload()
		}
	}

	const handleSearchClientByCode = async (event) => {
		event.preventDefault()

		handleBlurRut(event, setError);
	
		if(errors?.rut?.message===""){
			const body = {
				rut: getValues()?.rut
			}
			const response = await dispatch(searchClientByCode(body));
	
			setListCodeInternal(response?.data)		
		}
	}

	const handleCloseDialogInternal = () => {
		setDialogInternalCode(!dialogInternalCode)
	}

	const handleSelectItem = async (item) => {
	
		if(item?.hasOwnProperty('accountNumbers') && item?.accountNumbers?.length>1){
			setListDataUser(listDataUser => item.accountNumbers)
			setTextDialog("Para continuar, haga clic en el código interno que desee utilizar para acceder al sistema.")
		}else{
			setIsLoading(true)
			setDialogInternalCode(false)

			let internal_number = item?.name;
			let company_id = item?.company_id;

			if(item?.hasOwnProperty('accountNumbers')){
				internal_number = item?.accountNumbers?.length===1 ? item?.accountNumbers[0]?.name :
									null;
				company_id = item?.id
			}

			const body = {
				...getValues(),
					internal_number,
					company_id
			}
			let response = await dispatch(loginUser(body))

			setIsLoading(false)
			if(response.status){
				toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
				history.push('/transport-orders')
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
				return false
			}
		}
	}
	

	const formElement = {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		setError
	}

	const state = {
		isLoading,
		userData,
		switchShown, 
		shown,
		listCodeInternal,
		dialogInternalCode,
		listDataUser,
		textDialog
	}

	const actions = {
		logoutUser,
		handleSearchClientByCode,
		handleCloseDialogInternal,
		handleSelectItem
	}

	return {
		formElement,
		state,
		actions
	}
}