import Skeleton from 'react-loading-skeleton'
import package_1 from "assets/images/package-1.png"
import package_2 from "assets/images/package-2.png"
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Dashboard.module.css";

const DashboardCount = ({ countedOrders, isLoadingDashboard }) => {
	
	return (
		<section className="w-100">
                <section className={styles["space-container"]}>
                    <p className={styles["space-title"]}>Historial de envios</p>
                </section>
                <section className={styles["space-item__count"]}>
                    <div className={`${styles["space-history__container"]} pt-3`}>
                        <div className={styles["space-history_img"]}>
                            <img src={package_1} alt="Paquetes enviados" className="mr-3" />
                        </div>
                        <div className={styles["space-item__grow"]}>
                            <p className={styles["space-item__title"]}>Totales
                                <br/>
                                <b>Paquetes enviados</b> 
                            </p>
                        </div>
                        <p className={`text-right ${styles["space-history__count"]}`}>
                            {isLoadingDashboard ? <Skeleton width={70} count={1} /> : countedOrders?.received}
                        </p>
                    </div>
                    <div className={`${styles["space-history__container"]} pt-3`}>
                        <div className={styles["space-history_img"]}>
                            <img src={package_2} alt="Paquetes recibidos" className="mr-3" />
                        </div>
                        <div className={styles["space-item__grow"]}>
                            <p className={styles["space-item__title"]}>Totales
                                <br/>
                                <b>Paquetes recibidos</b> 
                            </p>
                        </div>
                        <p className={`text-right ${styles["space-history__count"]}`}>
                            {isLoadingDashboard ? <Skeleton width={70} count={1} /> : countedOrders?.realized}
                        </p>
                    </div>  
                </section>
            </section>
	);
};

export default DashboardCount;
