import { useState, useEffect } from 'react';
import TicketOrden from 'components/Withdrawals/Forms/PDF/TicketOrden';
import TicketCorrelativo from 'components/Withdrawals/Forms/PDF/TicketCorrelativo';
import loading_gif from "assets/images/loading.gif"
import { useSelector, useDispatch } from "react-redux";
import { getDataTicketRetiroPreOrden } from 'services/actions/transportOrderAction';
import styles from "styles/Retiro.module.css";
import { PDFViewer, Document } from "@react-pdf/renderer";


const FormPreview = () => {
	
	const { printNroRetiro } = useSelector((state) => state.preorden)
	const [ data, setData ] = useState([])
	const [ countPage, setCountPage ] = useState(0)
	const [ page, setPage ] = useState(1)
	const [ loading, setLoading ] = useState(true)
	const dispatch = useDispatch()

	const  getDataRetiro = async (page) => {
		setLoading(true)
		setPage(page)
		let response = await dispatch(getDataTicketRetiroPreOrden(printNroRetiro))
		if(response?.status){
			setData(data => response?.data)
			setCountPage(countPage => response?.total_paginate)
			setLoading(false)
		}
		
	}

	const nextPage = () => {
		if(page<countPage ){
			getDataRetiro(page+1)
		}		
	}

	const lastPage = () => {
		if(page > 1){
			getDataRetiro(page-1)
		}		
	}
  
	  useEffect(() => {
		  getDataRetiro(page)
		  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [])
	  
	return (
		<section style={{minHeight: '100vh', width: '100%'}}>
			<p className={styles["subtitle-completed"]}>Su número de retiro es: <b> {printNroRetiro}</b></p>
			{
				 data?.length>0 && (
					<div className={styles["space-button"]}>
							<button
								type="button"
								className="btn btn-secondary btn-size-33 mt-0 btn-select-multiple"
								disabled={loading ? true : page===1 ? true : false}
								onClick={(event) => lastPage()}
							>
								Anterior
							</button>
							<p className={styles["text-pagination"]}>{page}/{countPage}</p>
							<button
								type="button"
								className="btn btn-secondary btn-size-33 mt-0 btn-select-multiple"
								disabled={loading ? true : page===countPage ? true : false}
								onClick={(event) => nextPage()}
							>
								Siguiente
							</button>
						</div>
				)
			}	
			{loading && 
	    	 		<div className="container-pdf ">
						<img src={loading_gif} alt="Cargando..." />
		    	 		<p className="text-loading-pdf">{loading ? "Espere unos segundos, estamos generando los tickets de su retiro..." : ""}</p>
		    	 	</div>
			}	
						
			{
				(!loading && data?.length>0) && (
				<div>
					<PDFViewer style={{ width: "100%", height: "90vh", border: 'none' }} className="pdf-ticket">
						<Document onRender={()=> {
							setLoading(false)
						}} >
							{
								data?.map((item, key) => {
									if(!item?.isTicketCorrelativo) {
										return (
										<TicketOrden 
										key={key} 
										{...item} 
										/>)
									}else{
										return (
											<TicketCorrelativo
												key={key}
												numero_correlativo={item?.numero_correlativo}
											/>
										)
									}
								})
							}
						</Document>
					</PDFViewer>
				</div>
				)
			}
					
				
			
		</section>
	);
};

export default FormPreview;