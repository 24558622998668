import { useEffect } from "react";
import { Dropdown } from "react-bootstrap"
import MenuLeft from "components/Layout/MenuLeft";
import MenuBottom from "components/Layout/MenuBottom";
import MenuTop from "components/Layout/MenuTop";
import ModalForm from "components/Withdrawals/ModalForm";
import ModalSpinner from "components/Layout/ModalSpinner";
import retiro_new from "assets/images/retiro-new.png";
import user from "assets/images/user-dsk.png";
import { handlePasteOnlyOrden, validateOnlyOrden } from "utils/globals";
import { ToastContainer } from "react-toastify";
import { useLogin } from "hooks/Login/useLogin";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import { useCompany } from "hooks/Company/useCompany"
import { menuItems } from "services/menu/";
import { useSearchOrder } from "hooks/TransportOrders/useSearchOrder"
import { Link } from "react-router-dom";

const Layout = ({ children }) => {

	//const [ inact, setInact ] = useState(false);

	const { actions } = useLogin(null)
	const { sucursales, formViewActive, nextForm, saveDestination, formPreOrden, resetValudeDestination, 
		listFormaPagos, openModal, showModalNewWithdral } = useModalWithDrawal(null)

	const { logoutUser } = actions


	const { state: stateOrder, actions: actionsOrder } = useSearchOrder()
	const {isLoadingSearch, messageSearch, searchOrderNumber, userData } = stateOrder
	const { handleKeyDownOrderNumber, searchNumberOrder } = actionsOrder

	const { getBasicCompany, companyDataBasic } = useCompany()

	useEffect(() => {
		getBasicCompany()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<div className="container-fluid">
			<div className="row h-100vh">
				<div className="col-3">
					<MenuTop />
					<MenuLeft
						menuItems={menuItems}
						onCollapse={(inactive) => {
							//setInact(inactive);
						}}
						logoutUser={logoutUser}
						company={companyDataBasic}
					/>
				</div>
				<div className="col-9">
					<div className={`row pt-5 row-dsk`}>
						<div className="col-3">
							<button 
								type="button" 
								className={`btn btn-secondary btn-block btn-new-retiro mr-5`}
								onClick={()=>openModal()}
							>
								<div className="btn-image">
									<img src={retiro_new} alt="Retiro Nuevo" width={17} heigth={17} />
								</div>
								Retiro nuevo
							</button>
						</div>	
						<div className="col-5 col-xl-6">
							<div className="input-item">
								<input 
									type="text"
									className="input-main"
									placeholder="Buscar orden de flete"
									id="rut_header"
									name="rut_header"
									defaultValue={searchOrderNumber}
									onChange={(event) => searchNumberOrder(event.target.value)} 
									onKeyDown={(event) => handleKeyDownOrderNumber(event)} 
									onPaste={(event) => handlePasteOnlyOrden(event)}				
									onKeyPress={(event) => validateOnlyOrden(event)}
									maxLength={20}
								/>
								<span className="input-main--icon"></span>
							</div>
						</div>	
						<div className="col-3 col-md-4 col-xl-3">
							<Dropdown className="dropdown-container p-relative" drop={"left"}>
								<Dropdown.Toggle id="dropdown-basic" className="dropdown-user text-left" >
									<div className="pr-3 align-self-start">
										<img src={user} alt="" />
									</div>
									<div className="pr-2 dropdown-user__item">
										<p className="dropdown-user__name">{userData?.full_name}</p>
										{
											userData?.internal_number!==null && (
												<p className="dropdown-user__internal">Cuenta cliente: {userData?.internal_number}</p>
											)
										}										
									</div>
								</Dropdown.Toggle>

								<Dropdown.Menu align="right" flip={false}>
									<div className="dropdown-link__item">
										<Link className="dropdown-item" to="/account" align="right">
											Perfil
										</Link>
									</div>
									
									
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					{children}
				</div>
				
				<MenuBottom 
					menuItems={menuItems}
				/>
			</div>
			<ToastContainer />
			{showModalNewWithdral && <ModalForm 
					show={showModalNewWithdral} 
					handleClose={openModal} 
					formName={formViewActive} 
					sucursales={sucursales}
					nextForm={nextForm}
					saveDestination={saveDestination}
					multiple_destinatation={formPreOrden?.multiple_destinatation}
					resetValudeDestination={resetValudeDestination}
					listFormaPagos={listFormaPagos}
					/> 
			}
			{isLoadingSearch && 
	    		<ModalSpinner 
		    		spinnerShow={isLoadingSearch}
		    		description={messageSearch}
		    	/>
		    }
			
		</div>
	);
};

export default Layout;