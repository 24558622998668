import { useEffect } from "react"
import { useUtil } from "hooks/Utils/useUtil"
import { useFormAccount } from "hooks/Profile/useFormAccount"
import { validateOnlyNumber, onPaste, validateOnlyLetter } from "utils/validationInput"
import styles from "styles/Profile.module.css";


const FormAccount = () => {

    const { formElement, state, actions } = useFormAccount()

    const { register,  handleSubmit, errors, onSubmit, setError, setValue } = formElement
    const { isLoading, companyDataBasic } = state
    const { getDataInitial } = actions
    const { handleChangeEmail, handleChangePhoneNumber } = useUtil()

    useEffect(() => {
        getDataInitial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyDataBasic])
    
    return (
        <form className={styles["form-account"]} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
                <label htmlFor="rut" className={`${styles["label-title"]} mb-0`}>RUT</label>
                <input 
                    type="text" 
                    className="form-control form__input"
                    id="rut" 
                    name="rut"
                    aria-describedby="rut"
                    ref={register}
                    readOnly
                />
                {(errors?.rut?.message && errors?.rut?.message!=="")  && (
                    <p className="text-danger w-100 text-left">
                        {errors?.rut?.message}
                    </p>
                )}
            </div>
            
            <div className="form-group mb-4">
                <label htmlFor="fullname" className={`${styles["label-title"]} mb-0`}>Nombre y apellido</label>
                <input 
                    type="text" 
                    className="form-control form__input text-uppercase"
                    id="fullname" 
                    name="fullname"
                    aria-describedby="fullname"
                    ref={register({required: {value: true, message: 'Requerido'}})}
                    onKeyPress={(event) => validateOnlyLetter(event)}
                    onPaste={(event) => onPaste(event)}
                    maxLength={100}
                />
                {(errors?.fullname?.message && errors?.fullname?.message!=="")  && (
                    <p className="text-danger w-100 text-left">
                        {errors?.fullname?.message}
                    </p>
                )}
            </div>
                
            <div className="form-group mb-4">
                <label htmlFor="email" className={`${styles["label-title"]} mb-0`}>Correo electrónico</label>
                <input 
                    type="email" 
                    className="form-control form__input"
                    id="email" 
                    name="email"
                    aria-describedby="email"
                    ref={register({
                        required: { value: true, message: 'Requerido' },
                        //eslint-disable-next-line
                        pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
                    })} 
                    onBlur={(event) => handleChangeEmail(event, "", setError, setValue, "email")}
                    onPaste={(event) => onPaste(event)}
                    readOnly={true}
                />
                {(errors?.email?.message && errors?.email?.message!=="")  && (
                    <p className="text-danger w-100 text-left">
                        {errors?.email?.message}
                    </p>
                )}
            </div>
            
             <div className="form-group mb-4">
                <label htmlFor="address" className={`${styles["label-title"]} mb-0`}>Dirección principal</label>
                <input 
                    type="text" 
                    className="form-control form__input"
                    id="address" 
                    name="address"
                    aria-describedby="address"
                    ref={register({required: {value: true, message: 'Requerido'}})}
                />
                {(errors?.address?.message && errors?.address?.message!=="")  && (
                    <p className="text-danger w-100 text-left">
                        {errors?.address?.message}
                    </p>
                )}
            </div>
            
            <div className="form-group mb-4">
                <label htmlFor="telephone" className={`${styles["label-title"]} mb-0`}>Teléfono</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="telephone">+56</span>
                    </div>
                    <input 
                        type="text" 
                        className="form-control form__input"
                        id="telephone" 
                        name="telephone"
                        aria-describedby="telephone"
                        maxLength={9}
                        ref={register({
                            required: {value: true, message: 'Requerido'},
                            minLength: {value: 9, message: 'Mínimo 9 dígitos'},
                            //eslint-disable-next-line
                            pattern: { value: /^([0-9]+)$/, message: 'Teléfono inválido'},
                        })}
                        autoComplete="off"
                        onKeyPress={(event) => validateOnlyNumber(event)}
                        onPaste={(event) => onPaste(event)}
                        onBlur={(event) => handleChangePhoneNumber(event, "", setError, setValue)}
                    />
                </div>
                {(errors?.telephone?.message && errors?.telephone?.message!=="")  && (
                    <p className="text-danger w-100 text-left">
                        {errors?.telephone?.message}
                    </p>
                )}
            </div>
            
            <div className="form-group text-right">
                <button 
                    type="submit" 
                    className={`btn btn-secondary btn-save`}
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Guardar"}</button>
                </div>
        </form>
    )
}

export default FormAccount