import Layout from "components/Layout/Layout";
import FormAccount from "components/Profile/FormAccount"
import FormPassword from "components/Profile/FormPassword"
import styles from "styles/Profile.module.css";
import { useProfile } from "hooks/Profile/useProfile";


import { Tab } from 'react-bootstrap'

const Profile = () => {

	const { tabSelected, handleTabSelect } = useProfile()

	return (
		<Layout
			modulo="mis-retiros"
		>

			<Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
			<section className={styles["row-profile"]}>
				<div className={`row pb-5`}>
					<div className="col-12 col-md-4">
					<section className={styles["list-button"]}>
						<button 
							type="button" 
							className={`btn ${styles["btn-option"]} ${tabSelected==="form-account" && styles["btn-option--active"]}`} 
							onClick={(event) => handleTabSelect(event, "form-account")}
						>
							Cuenta
						</button>
						<button 
							type="button" 
							className={`btn ${styles["btn-option"]} ${tabSelected==="form-password" && styles["btn-option--active"]}`} 
							onClick={(event) => handleTabSelect(event, "form-password")}
						>
							Contraseña
						</button>
					</section>
					</div>
					<div className="col-12 col-md-8">
						{tabSelected==="form-account" && <FormAccount />}
						{tabSelected==="form-password" && <FormPassword  />}
					</div>
				</div>
				</section>
			</Tab.Container>


		</Layout>
	);

};

export default Profile;