import { 
	SEARCH_TRANSPORT_ORDER,
    LOADING_SEARCH_ORDER,
	SELECT_NUMBER_ORDER,
	DATA_ALL_ORDERS,
	LOADING_DASHBOARD_ORDER,
	DATA_WITHDRAWALS_ALL,
	COUNTED_ORDERS_ALL,
	DATA_ORDER_INVOICE,
	DATA_RESET_ORDERS
} from 'services/types/transportOrderType';


const INITIAL_STATE = {
	dataTransportOrder: null,
    isLoadingSearch: null,
    messageSearch: "Espere unos segundos, se esta consultando la orden de flete...",
	searchOrderNumber: "",
	listTransportOrders: [],
	allTransportOrders: [],
	isLoadingDashboard: false,
	listWithdrawals: [],
	countedOrders: {
		received: 0,
		realized: 0
	}, 
	listInvoices: [],
	allGlobalInvoices: []
};

const claimReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case SEARCH_TRANSPORT_ORDER:
			return {
				...state,
				dataTransportOrder: action.payload.data,
				isLoadingSearch: action.payload.loading
			}
		case LOADING_SEARCH_ORDER:
			return {
				...state,
                dataTransportOrder: action.payload.data,
				isLoadingSearch: action.payload.loading,
                messageSearch: action.payload.message
			}
		case SELECT_NUMBER_ORDER:
			return {
				...state,
				searchOrderNumber: action.payload
			}
		case DATA_ALL_ORDERS:
			return {
				...state,
				listTransportOrders: action.payload.listOrders,
				allTransportOrders: action.payload.listGlobalOrders
			}
		case LOADING_DASHBOARD_ORDER:
			return {
				...state,
				isLoadingDashboard: action.payload.loading,
			}
		case DATA_WITHDRAWALS_ALL:
			return {
				...state,
				listWithdrawals: action.payload
			}
		case COUNTED_ORDERS_ALL:
			return {
				...state,
				countedOrders: action.payload,
			}
		case DATA_ORDER_INVOICE:
			return {
				...state,
				listInvoices: action.payload.listInvoices,
				allGlobalInvoices: action.payload.listGlobalInvoices
			}
		case DATA_RESET_ORDERS:
			return {
				...state,
				allTransportOrders: action.payload.listOrders,
				allGlobalInvoices: action.payload.listOrders
			}
		default: return state;
	}
};

export default claimReducer;