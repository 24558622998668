export const getPhone = (phoneNumber, matrizInfoBasic) => {
  let telefono = "";
  if (matrizInfoBasic?.country?.code) {
    if (
      phoneNumber !== null &&
      phoneNumber?.startsWith(matrizInfoBasic?.country?.code)
    ) {
      telefono = phoneNumber?.slice(matrizInfoBasic?.country?.code?.length);
    } else {
      telefono = phoneNumber;
    }
  }
  return telefono;
};

export const validateOnlyLetter = (event) => {
  if (!/[ñíóáéú a-zA-ZÑÍÓÁÉÚ ]/.test(event.key)) {
    event.preventDefault();
  }
};

export const handlePasteOnlyLetter = (event) => {
  // Obtiene el texto pegado desde el portapapeles
  const pastedText = event.clipboardData.getData("text");

  // Verifica si el texto pegado contiene solo letras y espacios
  if (!/^[ñíóáéúa-zA-ZÑÍÓÁÉÚ\s]*$/.test(pastedText)) {
    // Evita que se pegue el texto no válido
    event.preventDefault();
  }
};

export const validateOnlyNumber = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const handlePasteOnlyNumber = (event) => {
  // Obtiene el texto pegado desde el portapapeles
  const pastedText = event.clipboardData.getData("text");

  // Verifica si el texto pegado contiene solo letras y espacios
  if (!/^[0-9\s]*$/.test(pastedText)) {
    // Evita que se pegue el texto no válido
    event.preventDefault();
  }
};

export const onPaste = (event) => {
  event.preventDefault();
  event.stopPropagation();
};


export const validateOnlyOrden = (event) => {
  if (!/[0-9E]/.test(event.key)) {
    event.preventDefault();
  }
};

export const handlePasteOnlyOrden = (event) => {
  // Obtiene el texto pegado desde el portapapeles
  const pastedText = event.clipboardData.getData("text");

  // Verifica si el texto pegado contiene solo letras y espacios
  if (!/^[0-9E]*$/.test(pastedText)) {
    // Evita que se pegue el texto no válido
    event.preventDefault();
  }
};
