import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Dashboard.module.css";
import { downloadDocumento } from "utils/downloadFile"
import icon_inbox from "assets/images/icon-inbox.svg";
import { Link } from "react-router-dom";

const DashboardInvoice = ({ listInvoices, isLoadingDashboard }) => {
	
	return (
		<section className="w-100">
            <section className={styles["space-container"]}>
                <p className={styles["space-title"]}>Mis facturas</p>
                    <Link to={`/transport-orders/invoices`}>
                        <button className={`btn btn-primary ${styles["space-btn"]}`}>Ver historial</button>
                    </Link>
            </section>
            <section className={`${styles["space-item"]} pt-5`}>
                {
                    (!isLoadingDashboard && listInvoices?.length===0) ? (
                        <div className={styles["options-not-records"]}>
                            <div><img src={icon_inbox} alt="No hay registros" /></div> 
                            <p className={styles["item-text__subtitle-fact"]}>
                                No hay órdenes disponibles
                            </p>
                        </div>
                    ) : (
                        <div className="table-responsive">
                            <table className={`table table-hover ${styles["table-invoice"]}`}>
                                <thead>
                                    <tr>
                                        <th scope="col">Nro Documento</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Descargar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isLoadingDashboard && listInvoices?.map((item, index) => 
                                            <tr key={index}>
                                                <td>No. {item?.nro_invoice}</td>
                                                <td>{item?.date_created}</td>
                                                <td>{item?.monto}</td>
                                                <td>
                                                    <span className={`badge ${styles["space-badge"]} ${styles[item?.state==="Pagada" ? "space-badge--green" : "space-badge--pink"]}`}>
                                                        {item?.state}
                                                    </span>
                                                </td>
                                                <td>
                                                    <button
                                                        className={`btn btn-light ${styles["btn--blue"]}`}
                                                        onClick={()=> downloadDocumento(item)}
                                                    >
                                                        PDF
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        isLoadingDashboard && (
                                            <tr>
                                                <td><Skeleton width={100} count={1} /></td>
                                                <td><Skeleton width={100} count={1} /></td>
                                                <td><Skeleton width={100} count={1} /></td>
                                                <td><Skeleton width={100} count={1} /></td>
                                                <td><Skeleton width={100} count={1} /></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    )
                }
                
            </section>
        </section>
	);
};

export default DashboardInvoice;
