import { useState } from "react"
import { updateProfile } from "services/actions/userAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { getPhone } from "utils/globals";
import { toast } from 'react-toastify'

export const useFormAccount = () => {
    
    const dispatch = useDispatch()
    const [ isLoading, setIsLoading ] = useState(false)
	const { userData, companyDataBasic } = useSelector((state) => state.users)
    

    const { register, handleSubmit, errors, control, setError, setValue, reset } = useForm({
        mode: "onChange",
        defaultValues: {
		fullname: "",
		telephone: "",
		email: "",
		address: "",
		rut: ""
	}})
	

    const onSubmit =  async (data, event) => {

        try{

            setIsLoading(true)
               
            let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(data.email)
            if(!isValidEmail){
                setValue("email", "")
                setError("email", { type: "manual", message: `El e-mail ingresado es incorrecto`})
                setIsLoading(false)
            	return false
            }

            if(data.telephone.length<9){
				setError("telephone", { type: "manual", message: `El número ingresado es incorrecto`})
				setIsLoading(false)
            	return false
            }

            regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
			const isValidTelephone = regex.test("+56"+data.telephone)
            if(!isValidTelephone){
                setValue("telephone", "")
				setIsLoading(false)
            	return false
            }

            const response = await dispatch(updateProfile({
                ...data, 
                telephone: "+56"+data.telephone,
                fullname: data?.fullname?.toUpperCase()
            }))
            setIsLoading(false)
            if(response.status){
                toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})				
            }else{
                toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            }
        }catch(error){
			setIsLoading(false)
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}   
    }

    const getDataInitial = () => {
        
        if(companyDataBasic!==null){
            reset({
                fullname: userData?.full_name,
                telephone:  getPhone(userData?.phone_number, companyDataBasic) ?? "",
                email: userData?.email,
                address: userData?.address,
                rut: userData?.rut
            })
        }
        
    }


    const formElement = {
        register, 
        handleSubmit,
        errors,
        onSubmit,
        control,
		setError,
		setValue
    }

    const state = {
        isLoading,
        companyDataBasic
    }

    const actions = {
        getDataInitial
    }

    return {
        formElement,
        state,
        actions
    }
}