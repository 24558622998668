import BaseLayoutLogin from "components/Layout/BaseLayoutLogin";
import ResetPassword from "components/Password/ResetPassword";
import FormCodePassword from "components/Password/FormCodePassword";
import FormNewPassword from "components/Password/FormNewPassword";
import FormMessage from "components/Password/FormMessage";

import password_frame from "assets/images/password-frame.svg";
import styles from "styles/Login.module.css";
import { Link } from "react-router-dom";
import { usePassword } from "hooks/Login/usePassword"
import { useUtil } from "hooks/Utils/useUtil"


export default function HomePassword () {

	const { state, formElement } = usePassword()
	const { handleBlurRut, handleChangeEmail } = useUtil()

	const { onSubmit, register, handleSubmit, errors, getValues, validatePassword } = formElement
	const { isLoading, nextForm } = state

	return (
		<BaseLayoutLogin>
			<form className={styles["form-login"]} onSubmit={handleSubmit(onSubmit)}>
				<div className="got-back" >
					<Link to="/" className="got-back__link">Volver</Link>
				</div>
				<section className={styles["container-reset"]}>

				{nextForm!=="form-message" && 
					<div className={styles["group-image--password"]}>
						<img src={password_frame} alt="Login" width={230} height={128} />
					</div>
				}
				
				{(nextForm==="form-forgot-password" || nextForm==="form-forgot-verified") && 
					<ResetPassword 
						formElement={formElement}
						state={state}
						handleBlurRut={handleBlurRut}
						handleChangeEmail={handleChangeEmail}
					/>
				}
				{nextForm==="form-code-password" && 
					<FormCodePassword 
						register={register}
						errors={errors} 
						isLoading={isLoading}
					/>
				}
				{nextForm==="form-new-password" && 
					<FormNewPassword 
						register={register}
						errors={errors} 
						isLoading={isLoading}
						getValues={getValues} 
						validatePassword={validatePassword}
					/>
				}

				{nextForm==="form-message" && 
					<FormMessage />
				}
				</section>
			</form>
		</BaseLayoutLogin>
	);
};