import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Login from "presentacions/Login"
import Register from "presentacions/Register"
import HomePassword from "presentacions/HomePassword"
import Dashboard from "presentacions/Ordenes/index"
import MyOficce from "presentacions/Oficinas"
import Reclamos from "presentacions/Reclamos/"
import Preguntas from "presentacions/Preguntas/"
import ListInvoice from "presentacions/Ordenes/ListInvoice"
//import ListadoRetiro from "presentacions/Retiros/ListadoRetiro"
import ListOrders from "presentacions/Ordenes/ListOrders"
import Profile from "presentacions/Profile";
//import Informe from "presentacions/Informe";
import BuscarOrden from "presentacions/Ordenes/BuscarOrden"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/normalize.css';
import 'styles/menu.css';
//import 'styles/globals.css';
import 'styles/globals-1.css';
  
function App() {

  const verifyLogged = (Component) => {
    return localStorage.getItem("user")!==null && JSON.parse(atob(localStorage.getItem("user"))) ? <Component /> : <Redirect to="/" />
  }
  
    return (
      <Router>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/auth/verify-customer" component={Register} />
            <Route exact path="/account/password" component={HomePassword} />
            <Route exact path="/account" render={() => verifyLogged(Profile)} />
            <Route exact path="/transport-orders" render={() => verifyLogged(Dashboard)} />
            <Route exact path="/offices" render={() => verifyLogged(MyOficce)} />
            <Route exact path="/claims" render={() => verifyLogged(Reclamos)} />
            <Route exact path="/questions" render={() => verifyLogged(Preguntas)} />
            <Route exact path="/transport-orders/invoices" render={() => verifyLogged(ListInvoice)} />
            <Route exact path="/transport-orders/search" render={() => verifyLogged(ListOrders)} />
            <Route exact path="/transport-orders/:order_number" render={() => verifyLogged(BuscarOrden)} />


            {/**<Route exact path="/mis-retiros/busqueda" render={() => verifyLogged(ListadoRetiro)} />
            
            <Route exact path="/retiro/:numero_retiro/informe" render={() => verifyLogged(Informe)} /> */}
            
        </Switch>
      </Router>
    );
  }
  
  export default App;
  