import styles from "styles/Login.module.css";

const FormCodePassword = ({ register, errors, isLoading }) => {

    return (
        <>
            <div className={`form-group w-100`}>
                <h3 className={styles["title-account"]}>¿Se te olvidó <br/>la contraseña?</h3>
                <p className={styles["text-account"]}>Ingresa el código <br/>que llegó a tu correo electrónico:</p>
            </div>
            <div className={`form-group mb-4 w-100`}>
                <input 
                    type="text" 
                    className={`form-control ${styles["form-input"]}`}
                    id="code" 
                    name="code"
                    aria-describedby="code"
                    placeholder="Código"
                    maxLength={6}
                    ref={register({required: {value: true, message: 'Requerido'}})}
                />
                {errors.code && (
                    <p className="text-danger w-100 text-left">
                        {errors?.code?.message}
                    </p>
                )}
            </div>
            <div className="form-group w-100">
                <button 
                    type="submit" 
                    className="btn btn-primary btn-block mb-4 pt-2 btn-claim"
                    disabled={isLoading}
                >{isLoading ? "Procesando..." : "Siguiente"}</button>
			</div>
        </>
    )
}

export default FormCodePassword