import { useEffect } from "react"
import Layout from "components/Layout/Layout";
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "styles/Dashboard.module.css";
import FormSearchAll from "components/Withdrawals/ViewAll/FormSearchAll";
import { useInvoice } from "hooks/TransportOrders/useInvoice"
import ReactPaginate from 'react-paginate';
import { downloadDocumento } from "utils/downloadFile"


const Dashboard = () => {
	
    const { actions, state, formElement } = useInvoice()
	
	const { pageCount, pageOffset, allGlobalInvoices } = state
	const { handlePageChange, resetList } = actions

	useEffect(() => {
		resetList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout modulo="inicio">
			<div className="row  padding-menu-top">
                <FormSearchAll 
					typeSearch="facturas" 
					itemBreadcrumb="Mis facturas"
					urlBreadCrumb="/transport-orders"
					formElement={formElement}
				/>
				<div className="col-12 pt-5 flex-table">
                    <div className="table-responsive flex-grow-1">
                        <table className={`table table-hover ${styles["table-invoice"]} table-order-hover`}>
                            <thead>
                                <tr>
                                    <th scope="col">Nro. documento</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Descargar</th>
                                </tr>
                            </thead>
                            <tbody>
							{
									 allGlobalInvoices?.map((item, index) => 
										<tr key={index}>
											<td>No. {item?.nro_invoice}</td>
											<td>{item?.date_created}</td>
											<td>{item?.monto}</td>
											<td>
												<span className={`badge ${styles["space-badge"]} ${styles[item?.state==="Pagada" ? "space-badge--green" : "space-badge--pink"]}`}>
													{item?.state}
												</span>
											</td>
											<td>
												<button
													className={`btn btn-light ${styles["btn--blue"]}`}
													onClick={()=> downloadDocumento(item)}
												>
													PDF
												</button>
											</td>
										</tr>
									)
								}
                            </tbody>
                        </table>
						{
							allGlobalInvoices?.length===0 && ( 
								<div className="rows-empty">
									<p  className="text-center">No hay registros disponibles</p>
								</div>
							)
						}
                    </div>
                    <div className={`w-100 fixed-btn pt-5 justify-content-end`}>
						<div>
							<ReactPaginate
								previousLabel="Anterior"
								nextLabel="Siguiente"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={handlePageChange}
								containerClassName="pagination"
								activeClassName="active"
								forcePage={pageOffset}
							/> 
						</div>
                    </div>
				</div>
               

			</div>
		</Layout>
	);

};

export default Dashboard;
