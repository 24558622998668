import styles from "styles/Retiro.module.css";
import { useDestination } from "hooks/WithDrawals/useDestination"
import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { validateOnlyLetter, handlePasteOnlyLetter, validateOnlyNumber, handlePasteOnlyNumber,
	onPaste
 } from "utils/globals";

const FormDestination = ({ toast, sucursales, listFormaPagos }) => {

	const {formElement, lastFormPreOrden, cargarOficinas, oficinaDestino, actions, state } = useDestination(toast)
	const {
        onSubmit,
        register,
        handleSubmit,
        errors,
        control,
        setValue,
    } = formElement;

	const { validatePhone, validateOnlyRUT } = actions

	const { isReadOnlyCustomer, companyDataBasic } = state
	

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className="mt-4">
				<div className="row mb-4">
				<div className="col">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="rut">RUT</label>
				      	<Controller
	                        render={(props) => (
	                            <MaskedInput
	                                mask={RutTextMask}
	                                className="form-control form__withadral_input"
	                                id="rut" 
	                                name="rut"
	                                value={props.value}
	                                onChange={(value)=>{
	                                    props.onChange(value)
	                                    setValue("rut", value?.target.value)
	                                }}
	                                //onBlur={(event) => handleBlurRut(event, setError)}
									onKeyDown={(event) => validateOnlyRUT(event)}
	                            />
	                        )}
	                        control={control}
	                        name="rut"  
	                        rules={{required: {value: true, message: 'Requerido'}}}
	                    />
				      	{(errors.rut && errors.rut.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.rut?.message}
					    	</p>
					    )}
				    </div>
				    <div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="razon_social">Nombre y Apellido</label>
				      	<input 
				      		type="text" 
				      		className="form-control form__withadral_input text-uppercase"
				      		id="razon_social" 
				      		name="razon_social"
				      		ref={register({required: {value: true, message: 'Requerido'}})}
							onKeyPress={(event) => validateOnlyLetter(event)}
							onPaste={(event) => handlePasteOnlyLetter(event)}
						    maxLength={150}
							readOnly={isReadOnlyCustomer}
				      	/>
				      	{(errors.razon_social && errors.razon_social.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.razon_social?.message}
					    	</p>
					    )}
				    </div>
				    
				  </div>
				
				<div className="row mb-4">
					<div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="telefono">Teléfono</label>
						  <div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="telephone">{companyDataBasic?.country?.code}</span>
							</div>
							<input 
								type="text" 
								className="form-control form__withadral_input" 
								id="telefono" 
								name="telefono"
								aria-describedby="telefono"
								ref={register({
									minLength: { value: 9, message: "Mínimo 9 dígitos" },
									required: { value: true, message: "Requerido" },
									//eslint-disable-next-line
									pattern: {
										value: /^([0-9]+)$/,
										message: "Teléfono inválido",
									},
									validate: validatePhone,
								})}
								autoComplete="off"
								onKeyPress={(event) => validateOnlyNumber(event)}
								onPaste={(event) => handlePasteOnlyNumber(event)}
								readOnly={isReadOnlyCustomer}
							/>
						</div>							  
				      	{(errors.telefono && errors.telefono.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.telefono?.message}
					    	</p>
					    )}
				    </div>
					<div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="telefono">E-mail</label>
						<input 
							type="email" 
							className="form-control form__withadral_input" 
							id="email" 
							name="email"
							autoComplete="off"
							aria-describedby="email"
							ref={register({
								//eslint-disable-next-line
								pattern: {
									value:
									/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
									message: "Email inválido",
								},
								required: { value: true, message: "Requerido" },
								minLength: { value: 5, message: "Mínimo 5 caracteres" },
							})}
							maxLength={100}
							//defaultValue={datos?.email || ""}
							onPaste={(event) => onPaste(event)}
							readOnly={isReadOnlyCustomer}
						/>
				      	{(errors.email && errors.email.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.email?.message}
					    	</p>
					    )}
				    </div>
				</div>
				<div className="row mb-4">
				    <div className="col">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="sucursal_destino_id">Sucursal</label>
						<Controller
	                        control={control}
	                        name="sucursal_destino_id"
							disabled={isReadOnlyCustomer}
	                        rules={{ required: {value: true, message: 'Requerido'} }}
	                        render = {(props) => {
	                            return (
	                            <select 
	                                value={props.value ?? ""}
	                                className="form-control form__withadral_input" 
	                                id="sucursal_destino_id"   
	                                onChange={e => {                              
	                                    props.onChange(e.target.value);
	                                    cargarOficinas(e, "destino", "")
	                                }}                         
	                            >
	                                <option value="">-Seleccione-</option>
	                                {
	                                	sucursales.length>0 && sucursales.map((item, key) => 
	                                		<option value={item.id} key={key}>{item.name}</option>
	                                	)
	                                }
	                            </select>
	                        )}}
	                        defaultValue={""}
	                />
						{errors.sucursal_destino_id && (
					    	<p className="text-danger mb-4">
					      		{errors?.sucursal_destino_id?.message}
					    	</p>
					    )}
				    </div>
					<div className="col">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="comuna">Comuna</label>
						<Controller
	                        control={control}
	                        name="comuna"
							disabled={isReadOnlyCustomer}
	                        rules={{ required: {value: true, message: 'Requerido'} }}
	                        render = {(props) => {
	                            return (
	                            <select 
									value={props.value ?? ""}
	                                className="form-control form__withadral_input" 
	                                id="comuna"   
	                                onChange={e => {                              
	                                    props.onChange(e.target.value);
	                                }}                         
	                            >
	                                <option value="">-Seleccione-</option>
	                                {
	                                	oficinaDestino.length>0 && oficinaDestino.map((item, key) => 
	                                		<option value={item.id} key={key}>{item.name}</option>
	                                	)
	                                }
	                            </select>
	                        )}}
	                        defaultValue={""}
	                />
						{errors.comuna && (
					    	<p className="text-danger mb-4">
					      		{errors?.comuna?.message}
					    	</p>
					    )}
				    </div>
				</div>
				<div className="row mb-4">
				    <div className="col-6">
				    	<label className={styles["form-withdrawals__label"]} htmlFor="forma_pago_id">Forma de pago</label>
						<Controller
	                        control={control}
	                        name="forma_pago_id"
							disabled={isReadOnlyCustomer}
	                        rules={{ required: {value: true, message: 'Requerido'} }}
	                        render = {(props) => {
	                            return (
	                            <select 
	                                value={props.value}
	                                className="form-control form__withadral_input" 
	                                id="forma_pago_id"   
	                                onChange={e => {                              
	                                    props.onChange(e.target.value);
	                                }}                         
	                            >
	                                <option value="">-Seleccione-</option>
	                                {
	                                	listFormaPagos?.length>0 && listFormaPagos?.map((item, key) => 
	                                		<option value={item.id} key={key}>{item.description}</option>
	                                	)
	                                }
	                            </select>
	                        )}}
	                        defaultValue={""}
	                />
						{errors.forma_pago_id && (
					    	<p className="text-danger mb-4">
					      		{errors?.forma_pago_id?.message}
					    	</p>
					    )}
				    </div>

				</div>
				<div className="row mb-4">
				    <div className="col">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="direccion">Dirección</label>
				      	<textarea 
				      		className="form-control form__withadral_area"
				      		id="direccion" 
				      		name="direccion"
				      		rows={2}
				      		cols={2}
				      		ref={register({required: {value: true, message: 'Requerido'}})}
							readOnly={isReadOnlyCustomer}
				      	></textarea>
				      	{errors.direccion && (
					    	<p className="text-danger mb-4">
					      		{errors?.direccion?.message}
					    	</p>
					    )}
				    </div>
				</div>
				
				<div className={styles["btn-modal"]}>
				  	<button 
				  		className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
				  		onClick={()=>lastFormPreOrden("confirmationMultipleDestination")}				  	>
			            Atrás
			        </button>
			        <button 
			        	type="submit"
			        	className="btn btn-secondary btn-size-33 btn-block mt-0" 
			        >
			            Siguiente
			        </button>
				</div>
				<div className="row mb-3">
				  	<div className={`col ${styles["item-container"]}`}>
				  		<div className={styles["item-selected"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  		<div className={styles["item-disabled"]}></div>
				  	</div>
				</div>
			</section>
		</form>
	);
};

export default FormDestination;