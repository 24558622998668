import { resetFormWithDrawal, nextFormPreOrden, saveMultipleDestination, resetDestination,
	openDialogCreate
 } from "services/actions/withdrawalAction"
import { getDataInitial } from "services/actions/officeAction";
import { useDispatch, useSelector } from "react-redux"


export const useModalWithDrawal = (toast) => {

	const dispatch = useDispatch()
	
	const { preorden, offices, formaPagos, users } = useSelector((state) => {
		return {
			preorden: state.preorden,
			offices: state.offices,
			formaPagos: state.formaPagos,
			users: state.users
		}
	})
	const { formViewActive, formPreOrden, showModalNewWithdral }  = preorden
	const { sucursales } = offices
	const { listFormaPagos } = formaPagos;
	const { companyDataBasic } = users;


	const getInitial = async () => {

		await dispatch(getDataInitial(companyDataBasic?.id))	
	}

	const openModal = () => {
	
		if(sucursales?.length===0){
			dispatch(getDataInitial(companyDataBasic?.id))
		}
		
		dispatch(resetFormWithDrawal())
		dispatch(resetDestination())
	
		dispatch(openDialogCreate(!showModalNewWithdral))
	
	};

	const nextForm = (nameForm) => {
		dispatch(nextFormPreOrden(nameForm))
	}

	const saveDestination = (value,  nameForm) => {
		
		dispatch(saveMultipleDestination(value))
		dispatch(nextFormPreOrden(nameForm))
	}

	const resetValudeDestination = (nameForm) => {

		dispatch(resetDestination())
		dispatch(saveMultipleDestination(true))
		dispatch(nextFormPreOrden(nameForm))
	}

	

	return {
		sucursales,
		formViewActive,
		openModal,
		showModalNewWithdral,
		nextForm,
		saveDestination,
		formPreOrden,
		resetValudeDestination,
		getInitial,
		listFormaPagos
	}
}