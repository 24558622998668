import circle_success from "assets/images/circle-success.svg";
import styles from "styles/Login.module.css";
import { Link } from "react-router-dom";


const FormMessage = () => {

    return (
        <>

            <div className={`form-group mt-5 pt-5 mb-5 pb-5`}>
                <div className={styles["mx-auto"]}>
                    <img src={circle_success} alt="Login" width={230} height={128} />
                </div>
                <p className={styles["text-account"]}>Tu contraseña <br/> se cambió exitosamente</p>
            </div>
            
                <div className="form-group w-100">
                <Link to="/"
                    className="btn btn-primary btn-block mb-4 pt-3 btn-claim"
                >Ingresar nuevamente</Link>
                </div>
        </>
    )
}

export default FormMessage

