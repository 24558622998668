import { useState } from "react"
import { getLoading, getInvoiceByClient,
    exportExcelPDFOrders, resetMyOrdersList
 } from "services/actions/transportOrderAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"


export const useInvoice = () => {
    
    const dispatch = useDispatch()
	const { listTransportOrders, isLoadingDashboard, listWithdrawals, countedOrders, listInvoices, allTransportOrders,
        allGlobalInvoices
     } = useSelector((state) => state.transportOrders)
     const { register, handleSubmit, control, getValues } = useForm()

     const [ showDialog, setShowDialog ] = useState(false)
     const [ infoPackage, setInfoPackage ] = useState(null)
     const [pageOffset, setPageOffset] = useState(0)
    const [pageCount, setPageCount] = useState(0)


    const handleCloseDialog =  () => {
        setInfoPackage(null)
        setShowDialog(false)
    }

    const handleOpenDialog =  (item) => {
        setInfoPackage(item?.number_withdrawal_order)
        setShowDialog(true)
    }

    const onSubmit =  async (data, typeSearch) => {
		await getListAwait(data, 0)
	}

    const getListAwait =  async (data, page) => {
		dispatch(getLoading(true, "Espere unos segundos, buscando las órdenes..."))

		let body = {
			fromDate: document.getElementById("fecha_orden_from").value,
			toDate: document.getElementById("fecha_orden_to").value
		}
    
		let response = await dispatch(getInvoiceByClient(page+1, 30, body))
		
		if(response?.status){
			setPageCount(response?.total_paginate ?? 0)
			setPageOffset(page)
		}
		
		dispatch(getLoading(false, ""))		
	}

    const downloadSearch = async (event, typeDownload, typeSearch) => {
		event.preventDefault()
		let nameTypeSearch = typeSearch==="packages" ? "retiros" : "ordenes"
		let nameFile = typeDownload==="excel" ? `listado-${nameTypeSearch}.xlsx` : `listado-${nameTypeSearch}.pdf` 

		await dispatch(exportExcelPDFOrders(typeDownload, nameFile))	
	}

    const handlePageChange = async (event) => {
		// when its content is loaded in useEffect.
		setPageOffset(event.selected);
        const data = getValues()
		await getListAwait(data, event.selected);
	};

    const resetList =  () => {
		dispatch(resetMyOrdersList())
	};
   
    const state = {
        listWithdrawals,
        listTransportOrders,
        isLoadingDashboard,
        countedOrders,
        listInvoices,
        showDialog,
        infoPackage,
        allTransportOrders,
        pageCount, 
        pageOffset,
        allGlobalInvoices
    }

    const actions = {
        handleCloseDialog,
        handleOpenDialog,
        downloadSearch,
        handlePageChange,
        resetList
    }

    const formElement = {
        register, 
        handleSubmit, 
        control, 
        getValues,
        onSubmit
    }

    return {
        state,
        actions,
        formElement
    }
}